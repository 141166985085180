// import API from "../../routes/api.routes";
import API from "../../routes/api.routes";
import { ApiMananger } from "../app/apiMananger";

export const paymentApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    paymentOrder: build.mutation({
      query(body: any) {
        const { id, ...data } = body;
        return {
          url: API.PAYMENT.ADDORDER(id),
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const { usePaymentOrderMutation } = paymentApi;
