import React, { InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | null | any;
}

function Input({ error, ...props }: InputProps) {
  return (
    <div>
      <input
        className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
        {...props}
      />
      {error ? (
        <span className="text-[red] ml-4 text-[.8rem]">{error}</span>
      ) : null}
    </div>
  );
}

export default Input;
