import { GlobalRoutesInterface } from "../setup/declarations";
import API from "./api.routes";
import { APP } from "./app.routes";

const ROUTES: GlobalRoutesInterface = {
  APP,
  API,
};

export default ROUTES;
