import React, { useMemo, useState } from "react";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import { useDispatch } from "react-redux";
import { setSkeep } from "../../store/features/userSlice";
import { ArrowRight } from "tabler-icons-react";

const data: {
  id: number;
  image: String;
  title: String;
  description: string;
}[] = [
  {
    id: 1,
    image: "charity-rafiki.png",
    title: "Collecté",
    description: `En un clic, on vient chercher le linge chez vous ou au bureau.`,
  },
  {
    id: 2,
    image: "frame.png",
    title: "Traité",
    description: `Vos articles seront lavés, séchés, repassés et pliés.`,
  },
  {
    id: 3,
    image: "take-away-cuate.png",
    title: "Livré",
    description: `On vous livre selon vos disponibilités au lieu indiqué !`,
  },
];

function Welcome() {
  const searchPage = useQuery();

  const dispatch = useDispatch();

  const [active, setActive] = useState<String>("1");

  const currentPage = data.find((item) => item.id.toString() === active);

  useMemo(() => {
    setActive(searchPage.get("page") ?? "1");
  }, [searchPage]);

  const navigation = useNavigate();

  return (
    <div
      className={`flex-col h-screen bg-primary flex items-center justify-between p-5 animate-spin-slow`}
      key={currentPage?.id}
    >
      <div className="flex justify-end w-full">
        <div
          className="flex items-center gap-1 text-third cursor-pointer"
          onClick={() => {
            dispatch(setSkeep());
            return navigation("/auth/sign-in");
          }}
        >
          <span className="">passer</span>
          <ArrowRight size={18} />
        </div>
      </div>
      <img
        src={`assets/welcomes/${currentPage?.image}`}
        alt="1"
        className="w-5/6 text-center"
      />

      <div className="">
        <div className="mb-[30px] flex justify-center gap-2">
          {data.map((item) => (
            <div
              key={item.id}
              className={` h-[10px] rounded-lg ${
                item.id.toString() === active
                  ? "bg-third w-[15px]"
                  : "bg-gray w-[15px]"
              }`}
            ></div>
          ))}
        </div>
        <h1 className="text-[1.5rem] font-bold text-center">
          {currentPage?.title}
        </h1>
        <p className="text-center text-[.8rem] py-10">
          {currentPage?.description}
        </p>
      </div>

      <Button
        title={
          data.at(-1)?.id === currentPage?.id
            ? "Commencer maintenant"
            : "Suivant"
        }
        onClick={() => {
          if (data.at(-1)?.id === currentPage?.id) {
            dispatch(setSkeep());
            return navigation("/auth/sign-in");
          }

          navigation("?page=" + (currentPage!.id + 1));

          // setActive((v) => v + 1);
        }}
      />
    </div>
  );
}

export default Welcome;
