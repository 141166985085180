import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../store/features/userSlice";
import { useLoginUserMutation } from "../../../store/features/userApi";
import { useForm } from "@mantine/form";
import Input from "../../../components/input";
import toast from "react-hot-toast";
import { useNotificationService } from "../../../services/push";

function SignIn() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { askForPermission } = useNotificationService();

  const [LOGIN, { isLoading }] = useLoginUserMutation();

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      username: (value) => (value ? null : "Veuillez remplir ce champs"),
      password: (value) => (value ? null : "Veuillez remplir ce champs"),
    },
  });

  const handleSignIn = form.onSubmit(async (values) => {
    try {
      const res: any = await LOGIN(values);

      if (!res?.data) {
        if (res?.error?.data?.message) {
          return toast.error(res?.error?.data?.message);
        }
        // console.log({ res });
        return toast.error("Credentials incorrect");
      }
      toast.success("Authentification reussi");
      dispatch(setCredentials(res?.data));
      navigation("/accueil", { replace: true });
      setTimeout(async () => {
        await askForPermission();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <div>
      <h1 className="font-bold">S'identifier</h1>

      <div className="my-6 text-[.8rem]">
        <p>Content de te revoir! Vous n'avez pas de compte ?</p>
        <Link to={"/auth/sign-up"} className="text-third">
          S'inscrire
        </Link>
      </div>

      <form className="mt-10" onSubmit={handleSignIn}>
        <div className="mb-8">
          <input
            name="username"
            type="text"
            {...form.getInputProps("username")}
            placeholder="Nom d'utilisateur"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.username && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.username}
            </span>
          )}
        </div>

        <Input
          name="password"
          type="password"
          placeholder="mot de passe"
          {...form.getInputProps("password")}
          // error={form.errors.password}
          error={form.errors.password}
          style={{ marginBottom: 30 }}
        />

        <Button title="CONNEXION" type="submit" loading={isLoading} />
      </form>
      <div className="mt-5 text-center">
        <Link to={"/forget-password"} className="text-third">
          Mot de passe oublie
        </Link>
      </div>
    </div>
  );
}

export default SignIn;
