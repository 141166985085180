import { APPInterface, RouteInterfate } from "../setup/declarations";
import Splash from "../pages/splash";
import { Navigate, Outlet } from "react-router-dom";
import Welcome from "../pages/splash/Welcome";
import SignIn from "../pages/auth/sign-in";
import SignUp from "../pages/auth/sign-up";
import Auth from "../pages/auth";
import Accueil from "../pages/accueil/indes";
import Commande from "../pages/commande";
import Abonnement from "../pages/abonnement";
import Compte from "../pages/compte";
import Lavage from "../pages/accueil/pages/lavage";
import Poids from "../pages/accueil/pages/lavage/pages/poids";
import Articles from "../pages/accueil/pages/lavage/pages/articles";
import Creneau from "../pages/accueil/pages/creneau";
import ValidateCommande from "../pages/accueil/pages/commande";
import SuccessCommande from "../pages/accueil/pages/commande/success";
import Payement from "../pages/abonnement/pages/payement";
import VerifyEmail from "../pages/auth/verify-email";
import Service from "../pages/accueil/pages/service";
import CommandeDetails from "../pages/commande/pages/details";
import Ramassage from "../pages/accueil/pages/creneau/pages/ramassage";
import Repassage from "../pages/accueil/pages/creneau/pages/repassage";
import ResetPassword from "../pages/auth/reset-password";
import ForgetPassword from "../pages/auth/forget-password";
import LavageSpecial from "../pages/accueil/pages/special";
import Payment from "../pages/commande/pages/payments";
import SuccessPayment from "../pages/commande/pages/payments/pages/success";
import CancelPayment from "../pages/commande/pages/payments/pages/cancel";
import Notifications from "../pages/notification";
import Notification from "../pages/notification/notification";
import EditProfile from "../pages/compte/edit";

export const publicRoutes: RouteInterfate[] = [
  {
    path: "/*",
    element: <Navigate to={"/welcome"} />,
  },
  {
    path: "/",
    element: <Navigate to={"/welcome"} />,
  },
  {
    element: <Splash />,
    path: "splash",
  },
  {
    element: <Welcome />,
    path: "welcome",
  },

  {
    element: <Auth />,
    path: "auth",
    subPath: [
      {
        element: <SignIn />,
        path: "sign-in",
      },
      {
        element: <SignUp />,
        path: "sign-up",
      },
    ],
  },
  {
    element: <ForgetPassword />,
    path: "forget-password",
  },
  {
    element: <ResetPassword />,
    path: "verify-otp",
  },
  { element: <VerifyEmail />, path: "verify-email/:token" },
];

export const freeRoutes: RouteInterfate[] = [
  {
    element: <Outlet />,
    path: "payment",
    subPath: [
      {
        element: <SuccessPayment />,
        path: "success",
      },
      {
        element: <CancelPayment />,
        path: "cancel",
      },
    ],
  },
];

export const privateRoutes: RouteInterfate[] = [
  {
    path: "/*",
    element: <Navigate to={"/accueil"} />,
  },
  {
    path: "/",
    element: <Navigate to={"/accueil"} />,
  },
  {
    path: "accueil",
    element: <Outlet />,
    subPath: [
      {
        path: "",
        element: <Accueil />,
      },
      {
        path: "special",
        element: <LavageSpecial />,
      },
      {
        path: ":typeId",
        element: <Lavage />,
        subPath: [
          {
            path: "",
            element: <Poids />,
          },
          { path: "articles", element: <Articles /> },
        ],
      },
      {
        path: "lavage-type",
        element: <Service />,
      },
      {
        path: "creneau",
        element: <Creneau />,
        subPath: [
          {
            path: "",
            element: <Ramassage />,
          },
          {
            path: "repassage",
            element: <Repassage />,
          },
        ],
      },
      {
        path: "commande",
        element: <Outlet />,
        subPath: [
          { path: "", element: <ValidateCommande /> },
          { path: "success", element: <SuccessCommande /> },
        ],
      },
    ],
  },
  {
    element: <Outlet />,
    path: "commande",
    subPath: [
      {
        element: <Commande />,
        path: "",
      },
      {
        element: <Outlet />,
        path: ":id",
        subPath: [
          {
            element: <CommandeDetails />,
            path: "",
          },
          {
            element: <Payment />,
            path: "payment",
          },
        ],
      },
    ],
  },
  {
    element: <Outlet />,
    path: "abonnement",
    subPath: [
      {
        element: <Abonnement />,
        path: "",
      },
      {
        element: <Payement />,
        path: "payement",
      },
    ],
  },
  {
    element: <Compte />,
    path: "compte",
  },
  {
    element: <Notifications />,
    path: "notifications",
    subPath: [{ element: <Notification />, path: ":id" }],
  },
  {
    element: <EditProfile />,
    path: "profile/edit",
  },
];

export const APP: APPInterface = {
  privateRoutes,
  publicRoutes,
  freeRoutes,
};
