import React from "react";
import { NavLink } from "react-router-dom";
import { Home, Plus, User } from "tabler-icons-react";

const bottomTabData = [
  { id: 1, label: "Accueil", href: "/accueil", icon: Home },
  { id: 2, label: "Commande", href: "/commande", icon: Plus },
  // { id: 3, label: "Abonnement", href: "/abonnement", icon: Subscript },
  { id: 4, label: "Compte", href: "/compte", icon: User },
];

function BottomTab() {
  return (
    <div className="grid grid-cols-3 border-t-[.5px] border-[#e8e8e8] h-full">
      {bottomTabData.map((item) => (
        <NavLink
          to={item?.href}
          key={item?.id}
          className={({ isActive }) =>
            `flex flex-col items-center justify-center  color-white`
          }
        >
          {({ isActive }) => (
            <>
              <div
                className={`${
                  isActive
                    ? "bg-secondary p-2 rounded-full mt-[-18px] border-t-2 border-[#fff]"
                    : null
                }`}
              >
                <item.icon
                  className={`${isActive ? "text-white" : "text-black"}`}
                  size={18}
                />
              </div>
              <span
                className={`${
                  isActive ? "text-secondary" : "text-black"
                } text-[.7rem]`}
              >
                {item?.label}
              </span>
            </>
          )}
        </NavLink>
      ))}
    </div>
  );
}

export default BottomTab;
