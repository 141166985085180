import React, { ReactElement } from "react";
import { JsxElement } from "typescript";

function RenderData<T>({
  isLoading = false,
  data = [],
  render,
  renderEmpty,
}: {
  isLoading?: boolean;
  data: T[] | T;
  renderEmpty?: ReactElement | JsxElement | string | null;
  render: (item: T, index?: number) => ReactElement | JsxElement | string;
}) {
  return (
    <>
      {isLoading
        ? "Loading"
        : !data || (Array.isArray(data) && data.length === 0)
        ? renderEmpty ?? "Pas de données"
        : Array.isArray(data)
        ? data?.map(render)
        : render(data)}
    </>
  );
}

export default RenderData;
