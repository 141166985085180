import React from "react";
import { Template } from "../../../../layouts/Template";
import TopBar from "../../../../components/topBar";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import useGetOders from "../../../../Hooks/useGetOders";
import { usePlaceOrderMutation } from "../../../../store/features/orderApi";
// import { useDispatch } from "react-redux";
// import { claerOrders } from "../../../../store/features/orderSlice";
import Divider from "../../../../components/divider";
import { toast } from "react-hot-toast";
import useGetCurrentService from "../../../../Hooks/useGetCurrentService";
import { SERVICES } from "../../../../setup/constants";

function ValidateCommande() {
  const navigation = useNavigate();

  // const dispatch = useDispatch();

  const {
    orders,
    deliveryTime,
    collectionTime,
    weight,
    amountArticle,
    serviceId,
    priceExpress,
  } = useGetOders();

  const [placeOder, { isLoading }] = usePlaceOrderMutation({});

  const serviceSlogan = useGetCurrentService();

  const handlePlaceOrder = async () => {
    const body = {
      orderItems: orders,
      collectionTime: new Date(`${collectionTime}`).toLocaleDateString("fr", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
      deliveryTime: new Date(`${deliveryTime}`).toLocaleDateString("fr", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
      weight: parseInt(weight.toString()),
      price:
        priceExpress! +
        SERVICES.find((service) => service.slogan === serviceSlogan)
          ?.transport!,
      amountArticle: amountArticle,
      serviceId: serviceId,
    };

    try {
      const res: any = await placeOder({ ...body });
      // console.log({ res });
      if (!res?.data) {
        toast.error("error! reessayer");
        return;
      }
      // dispatch(claerOrders());
      navigation("success", { replace: true });
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Template
      topBar={<TopBar title="Detail de la Commande" />}
      topBarStyle={{ height: 60 }}
      mainStyle={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: 15,
      }}
      bottomBar={
        <div className="h-full flex items-center px-4">
          <Button
            title="VALIDER LA COMMANDE"
            onClick={handlePlaceOrder}
            loading={isLoading}
          />
        </div>
      }
    >
      {/* Part 1 */}
      {/* <CommandeCard /> */}

      {/* Part 2 */}
      <div className="border-[1px] border-gray rounded-xl flx flex-col gap-3">
        <div className="p-4">
          <h3 className="mb-2 font-bold text-[.9rem]">
            Details de la commande
          </h3>

          {/* {orders.map((commande) => (
            <div
              className="flex justify-between mb-1"
              key={commande?.articleId}
            >
              <p className="text-[.8rem]">
                {commande?.quantity} {commande?.article?.name}
              </p>
              <p className="text-[.8rem]">{commande?.weight} kg</p>
              <p className="text-[.8rem] font-bold">
                {commande?.quantity} Fcfa
              </p>
            </div>
          ))} */}
        </div>

        <Divider />

        <div className="p-2">
          <div className="flex justify-between mb-1">
            <p className="text-[.8rem]">nombre d'articles</p>
            <p className="text-[.8rem] font-bold">{amountArticle} articles</p>
          </div>
          {serviceSlogan === "R" ? null : (
            <div className="flex justify-between mb-1">
              <p className="text-[.8rem]">poids</p>
              <p className="text-[.8rem] font-bold">
                {parseInt(weight.toString()).toFixed()} kg
              </p>
            </div>
          )}
        </div>

        <Divider />

        <div className="p-2">
          <div className="flex justify-between mb-1">
            <p className="text-[.8rem]">Montant de la commande</p>
            <p className="text-[.8rem] font-bold">
              {priceExpress?.toFixed()} Frcfa
            </p>
          </div>
          <div className="flex justify-between mb-1">
            <p className="text-[.8rem]">Frais de livraison</p>
            <p className="text-[.8rem] font-bold">
              {
                SERVICES.find((service) => service.slogan === serviceSlogan)
                  ?.transport
              }{" "}
              Fcfa
            </p>
          </div>
        </div>

        <Divider />

        <div className="p-2">
          <div className="flex justify-between mb-1">
            <p className="text-[.8rem]">Total</p>
            <p className="text-[1rem] font-bold">
              {(
                priceExpress! +
                SERVICES.find((service) => service.slogan === serviceSlogan)
                  ?.transport!
              ).toFixed()}{" "}
              Frcfa
            </p>
          </div>
        </div>
        <Divider />

        <div className="p-2">
          <div className="flex justify-between mb-1">
            <p className="text-[.8rem]">creneau de ramassage</p>
            <p className="text-[.8rem] font-bold">
              {new Date(`${collectionTime} `).toLocaleDateString("fr", {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
          <div className="flex justify-between mb-1">
            <p className="text-[.8rem]">Date de la livraison</p>
            <p className="text-[.8rem] font-bold">
              {new Date(`${deliveryTime}`).toLocaleDateString("fr", {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
        </div>
      </div>
    </Template>
  );
}

export default ValidateCommande;
