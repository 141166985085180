import React, { useState } from "react";
import Input from "../../../components/input";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import Button from "../../../components/button";
import { ArrowLeft } from "tabler-icons-react";
import {
  useOtpVerificationMutation,
  useResetPasswordMutation,
} from "../../../store/features/userApi";
import toast from "react-hot-toast";
import Divider from "../../../components/divider";

function ResetPassword() {
  const navigation = useNavigate();

  const [RESET_PASSWOR, { isLoading }] = useResetPasswordMutation({});
  const [OTP_VERIFICATION, { isLoading: isOtpVerificationLoading }] =
    useOtpVerificationMutation({});

  const [otpVerification, setOtpVerification] = useState(false);

  const form = useForm({
    initialValues: {
      code: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      password: (value) => (value ? null : "Veuillez remplir ce champs"),
      confirmPassword: (value, values) =>
        values.password === values.confirmPassword
          ? null
          : "Les mots de passe ne correspondent pas",
      code: (value) => (value ? null : "Veuillez remplir ce champs"),
    },
  });

  const handleSendEmail = async (value: any) => {
    try {
      const res: any = await RESET_PASSWOR({ ...value });
      if (!res.error) {
        toast.success("Modifier cation mot de passe reussi", {});

        return navigation("/auth/sign-in", { replace: true });
      }
      toast.error("Une erreur c'est produite");
    } catch (error) {
      console.log({ error });
    }
  };

  const handleOtpVerify = async () => {
    if (form.errors?.code) {
      return;
    }
    try {
      const res: any = await OTP_VERIFICATION({ code: form.values.code });

      if (res?.data.isOtpVerified) {
        setOtpVerification(res?.data.isOtpVerified);
        return toast.success(res?.data.message);
      }
      // console.log({ res });
      return toast.error(res?.data.message);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="w-full h-full p-3 bg-white">
      <div
        className="p-2  mb-3 w-40"
        onClick={() => navigation("/auth/sign-in")}
      >
        <ArrowLeft size={40} />
      </div>
      <h1 className="font-bold mb-7">Recuperation de mot de passe</h1>

      <div className="mb-10 flex flex-nowrap gap-4">
        <Input
          name="code"
          type="number"
          placeholder="code otp"
          error={!form.values?.code ? "Remplir le champ" : null}
          {...form.getInputProps("code")}
          disabled={otpVerification}
          maxLength={5}
        />

        <Button
          title={otpVerification ? "Verifier ✔" : "Verifier"}
          style={{
            width: 150,
            backgroundColor: otpVerification ? "green" : undefined,
          }}
          disabled={!form.values?.code || otpVerification}
          loading={isOtpVerificationLoading}
          onClick={handleOtpVerify}
        />
      </div>

      {otpVerification ? (
        <>
          <Divider />

          <div className="mt-10 text-sm font-bold">
            Veuillez mettre votre nouveau mot de passe
          </div>
          <form
            onSubmit={form.onSubmit(handleSendEmail)}
            className="flex flex-col gap-5 mt-6"
          >
            <Input
              name="password"
              type="password"
              placeholder="nouveau mot de passe"
              {...form.getInputProps("password")}
              error={form.errors?.password}
            />

            <Input
              name="confirsmPassword"
              type="password"
              placeholder="Confirm ton nouveau mot de passe"
              {...form.getInputProps("confirmPassword")}
              error={form.errors?.confirmPassword}
            />

            <Button
              title="modifier le mot de passe"
              type="submit"
              loading={isLoading}
            />
          </form>
        </>
      ) : null}
    </div>
  );
}

export default ResetPassword;
