import { usePushSubscriptionMutation } from "../../store/features/pushApi";

export const useNotificationService = () => {
  const [pushSubscription, { isError, error, data }] =
    usePushSubscriptionMutation();
  isError && console.log({ error });
  data && console.log(data);

  const askForPermission = async () => {
    try {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        const registration = await navigator.serviceWorker.register(
          "/service-worker.js"
        );

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BFUMNUjAkj4UO5aKrJxpWDQsoLyS4F2HFXeOvceLmH4rvceSk-3Sw9mV2o7PCHZDgoMVMFcOBHxtMUEkbCS_V80",
        });

        const { keys } = subscription.toJSON();
        const { p256dh, auth }: any = keys;
        const subs = {
          endpoint: subscription.endpoint,
          keys: {
            p256dh,
            auth,
          },
        };

        await pushSubscription(subs);
      }
    } catch (error) {
      console.error("Error requesting permission:", error);
    }
  };

  return { askForPermission };
};
