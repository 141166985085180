// CustomDateInput.tsx
import React from "react";

interface CustomDateInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const InputDate: React.FC<CustomDateInputProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <input
      type="date"
      className="border rounded-full px-2 py-1 w-full"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

export default InputDate;
