import React, { useState } from "react";
import { Search } from "tabler-icons-react";
import Button from "../../../../../../components/button";
import { useNavigate } from "react-router-dom";
import { useGetAllSimpleArticlesQuery } from "../../../../../../store/features/articleApi";
import RenderData from "../../../../../../components/renderData";
import { useDispatch } from "react-redux";
import {
  addOrder,
  setPrice,
} from "../../../../../../store/features/orderSlice";
import { OderItem } from "../../../../../../setup/declarations";
import useGetOders from "../../../../../../Hooks/useGetOders";
import ArticleCard from "../../../../components/ArticleCard";

function Articles() {
  const navigation = useNavigate();
  // const { typeId } = useParams<{ typeId: string }>();

  const { orders, price } = useGetOders();

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  // const { data, isLoading } = useGetAllArticlesByCategoryQuery<{
  //   data: { name: string; weight: number; id: string }[];
  //   isLoading: boolean;
  // }>(typeId);
  const { data, isLoading } = useGetAllSimpleArticlesQuery<{
    data: { articles: { name: string; weight: number; id: string }[] };
    isLoading: boolean;
  }>({});

  // console.log({ data });

  const handleAddOrder = (
    type: "add" | "moins",
    id: string,
    weight: number,
    article: any
  ) => {
    dispatch(addOrder({ type, id, weight, article }));
    dispatch(setPrice());
  };

  return (
    <div className="flex flex-col gap-4 relative">
      <div className="flex gap-4 border-[1px] border-[#c9c9c9] h-[40px] items-center px-3 rounded-full">
        <Search color="#c9c9c9" />
        <input
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Rechercher un article"
          type="text"
          name="search"
          id="search"
          className="h-full flex-1 outline-0"
        />
      </div>

      <p className="text-[.9rem]">Saisissez le nombre d'articles à nettoyer.</p>

      <div className="flex flex-col gap-3 mb-20">
        <RenderData
          isLoading={isLoading}
          data={
            data
              ? data.articles.filter((value) =>
                  value.name.toLowerCase().includes(search.toLowerCase())
                )
              : []
          }
          render={(article) => (
            <ArticleCard
              article={article}
              key={article?.id}
              {...article}
              onAddOrder={handleAddOrder}
              quantity={
                orders.find((order: OderItem) => order.articleId === article.id)
                  ? orders.find(
                      (order: OderItem) => order.articleId === article.id
                    )!.quantity
                  : 0
              }
            />
          )}
        />
      </div>

      <div className="fixed bottom-0 left-0 w-full h-16 p-4 bg-[#fff]">
        <Button
          title={`Continuer - ${Math.round(price as number)} FCFA`}
          onClick={() => navigation("/accueil/creneau")}
          disabled={orders.length === 0}
        />
      </div>
    </div>
  );
}

export default Articles;
