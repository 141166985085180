import { Check } from "tabler-icons-react";

function PricingModeCard({
  label,
  exp,
  image,
  check,
  onCheck,
}: {
  label: string;
  id: number;
  exp: string;
  image: string;
  check?: boolean;
  onCheck?: () => void;
}) {
  return (
    <div
      onClick={onCheck}
      className="flex justify-between p-4 border-2 border-gray rounded-xl cursor-pointer hover:bg-gray"
    >
      <div className="flex gap-2">
        <div className="w-[50px] h-[40px] border-[1px] p-2 rounded-lg border-gray flex items-center justify-center bg-white">
          <img src={`../../assets/payements/${image}`} alt="payement" />
        </div>
        <div className="text-[.8rem] flex flex-col gap-1">
          <h3 className="text-[#4AC5AC] font-bold">{label}</h3>
          <p className="">Expiration {exp}</p>
          <p className="">
            Définir par défaut
            <span className="text-[#4AC5AC] ml-2">Modifier</span>
          </p>
        </div>
      </div>

      <div
        className={`w-[25px] h-[25px] rounded-full ${
          check ? "bg-[#4AC5AC]" : null
        } flex items-center justify-center border-2 border-gray`}
      >
        {check ? <Check color="#fff" size={13} /> : null}
      </div>
    </div>
  );
}

export default PricingModeCard;
