import React, { useState } from "react";
import { Template } from "../../../../layouts/Template";
import TopBar from "../../../../components/topBar";
import Button from "../../../../components/button";
import PricingModeCard from "../../../../components/PricingModeCard";
import { PAYMENTMETODEDATA } from "../../../../setup/constants";
import { usePaymentOrderMutation } from "../../../../store/features/paymentApi";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useGetOrderQuery } from "../../../../store/features/orderApi";
import { Order } from "../../../../setup/declarations";

function Payement() {
  const params = useParams<{ id: string }>();

  const [check, setCheck] = useState<number | null>(null);

  const [paymentOrder] = usePaymentOrderMutation();

  const { data, isLoading } = useGetOrderQuery<{
    data: Order;
    isLoading: boolean;
  }>(parseInt(params?.id!));

  const handlePay = async () => {
    try {
      const res: any = await paymentOrder({
        id: parseInt(params?.id!),
        item_name: "item_name_" + data.orderNumber,
        item_price: data?.price?.toString(),
        ref_command: data.orderNumber,
        command_name: data.orderNumber,
      });

      console.log({ res });

      if (res.data) {
        return window.open(res?.data?.redirect_url);
      }
      return toast.error("Payment Error");
    } catch (error) {
      toast.error("Error");
    }
  };

  return (
    <Template
      topBar={<TopBar title="Procéder au paiement" />}
      topBarStyle={{ height: 60 }}
      mainStyle={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
      bottomBar={
        <div className="h-full flex items-center px-4">
          <Button
            title="PAYER - 25.000 FCFA"
            disabled={check ? false : true}
            onClick={handlePay}
          />
        </div>
      }
    >
      {PAYMENTMETODEDATA.map((item) => (
        <PricingModeCard
          key={item.id}
          {...item}
          onCheck={() => setCheck(item?.id)}
          check={check === item.id}
        />
      ))}
    </Template>
  );
}

export default Payement;
