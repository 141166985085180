import React, { useEffect, useMemo, useState } from "react";
import { Template } from "../../layouts/Template";
import BottomTab from "../../components/bottomTab";
import Logo from "../../components/logo";
import { Link } from "react-router-dom";
import useQuery from "../../Hooks/useQuery";
import CommandeCard from "../../components/CommandeCard";
import {
  orderApi,
  // useGetAllOrdersQuery,
  useGetAllUserOrdersQuery,
} from "../../store/features/orderApi";
import RenderData from "../../components/renderData";
import { Order } from "../../setup/declarations";
import { socket } from "../../services/sockets";
import { useDispatch } from "react-redux";

function Commande() {
  const searchParams = useQuery();

  // const { data, isLoading } = useGetAllOrdersQuery<{
  //   data: Order[];
  //   isLoading: boolean;
  // }>({});

  const dispatch = useDispatch();

  const { data, isLoading } = useGetAllUserOrdersQuery<{
    data: { orders: Order[] };
    isLoading: boolean;
  }>({});

  const [isActive, setIsActive] = useState("tout");

  useMemo(() => {
    setIsActive(searchParams.get("type") ?? "tout");
  }, [searchParams]);

  useEffect(() => {
    socket.on("orderUpdated", (value) => {
      dispatch(orderApi.util.invalidateTags(["Orders"]));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(data);

  return (
    <Template
      bottomBar={<BottomTab />}
      topBar={
        <div className="flex gap-4 h-24 items-center px-5 ">
          <Logo />
          <p className="text-[1rem] font-bold">Commandes</p>
        </div>
      }
      topBarStyle={{ height: 80 }}
    >
      {/* Commande Page */}
      <div className="flex justify-around sticky top-0 z-10 bg-[#fff]">
        <RenderData
          data={[
            { href: "?type=tout", label: "Tout" },
            { href: "?type=impayees", label: "Impayees" },
            { href: "?type=payees", label: "Payees" },
          ]}
          render={(item, index) => (
            <Link
              to={item?.href}
              key={index}
              className={`w-1/2 border-b-2 ${
                isActive === item.href.split("=").at(-1)
                  ? "border-third"
                  : "border-gray"
              } flex justify-center p-3`}
            >
              <h2
                className={`text-[.9rem] font-bold text-${
                  isActive === item.href.split("=").at(-1) ? "third" : "black"
                }`}
              >
                {item?.label}
              </h2>
            </Link>
          )}
        />
      </div>

      <div className="p-2 flex flex-col gap-4">
        <RenderData
          isLoading={isLoading}
          data={
            !data
              ? []
              : [...data?.orders].filter((order) =>
                  isActive === "impayees"
                    ? order.status === "PENDING"
                    : isActive === "payees"
                    ? order.status === "ACCEPTED"
                    : true
                )
          }
          render={(item) => (
            <Link key={item.id} to={`${item?.id}`}>
              <CommandeCard {...item} />
            </Link>
          )}
        />
      </div>
    </Template>
  );
}

export default Commande;
