import React from "react";
import { ButtonProps } from "../../setup/declarations";

const Button: React.FC<ButtonProps> = ({
  title,
  loading,
  disabled,
  ...props
}) => {
  return (
    <button
      disabled={disabled || loading}
      {...props}
      className={` disabled:opacity-50 disabled:hover:bg-secondary bg-secondary hover:bg-[#153c54e2] text-white font-bold h-[40px] px-4 rounded-full w-full text-[.8rem]`}
    >
      {title}
      {loading ? "..." : null}
    </button>
  );
};

export default Button;
