import React from "react";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import useGetSkeep from "../../Hooks/useGetSkeep";

function Splash() {
  const navigation = useNavigate();

  const isSkeep = useGetSkeep();

  return (
    <div
      className={`flex items-center justify-center flex-col h-screen bg-primary p-5`}
    >
      <img
        src="assets/logos/Fot_Fat_Fat_transparent.png"
        className="w-3/6 text-center mb-14"
        alt="logo"
      />

      <Button
        title="Commencer"
        onClick={() => navigation(isSkeep ? "/auth/sign-in" : "/welcome")}
        style={{ width: 120 }}
      />
    </div>
  );
}

export default Splash;
