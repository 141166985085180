import React from "react";
import { Template } from "../../../../layouts/Template";
import TopBar from "../../../../components/topBar";
import { useGetAllQuery } from "../../../../store/features/serviceApi";
import { Service as IService } from "../../../../setup/declarations";
import RenderData from "../../../../components/renderData";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceId,
  setService,
} from "../../../../store/features/orderSlice";

function Service() {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const serviceId = useSelector(getServiceId);

  const { data, isLoading } = useGetAllQuery<{
    data: IService[];
    isLoading: boolean;
  }>({});

  // console.log({ data });

  return (
    <Template
      topBar={<TopBar title="Lavage type" />}
      topBarStyle={{ height: 60 }}
      mainStyle={{ padding: "10px" }}
      bottomBar={
        <Button
          title="Continer vers le creneau"
          onClick={() => {
            if (!serviceId) {
              return toast.error("selectionner un service", {
                position: "top-center",
              });
            }
            navigation("/accueil/creneau");
          }}
        />
      }
      bottomBarStyle={{ padding: 10 }}
    >
      <RenderData
        data={data}
        isLoading={isLoading}
        render={(service) => (
          <div
            key={service.id}
            className={`border-2 border-gray ${
              serviceId === service.id ? "bg-green-100" : null
            } mb-3 rounded-lg p-3 flex justify-between`}
            onClick={() => {
              dispatch(setService({ id: service.id }));
            }}
          >
            <span className="">{service.name}</span>
            <span className="">{service.price} FCFA par article</span>
          </div>
        )}
      />
    </Template>
  );
}

export default Service;
