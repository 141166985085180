import { Minus, Plus } from "tabler-icons-react";

function ArticleCard({
  name,
  weight,
  id,
  quantity,
  onAddOrder,
  article,
  type = "simple",
  price,
}: {
  name: string;
  weight: number;
  price?: number;
  id: string;
  quantity?: number;
  article: any;
  type?: "special" | "simple";
  onAddOrder: (
    type: "add" | "moins",
    id: string,
    weight: number,
    price: number,
    article: any
  ) => void;
}) {
  return (
    <div className="flex justify-between items-center border-[1px] border-[#c9c9c9] rounded-xl p-3">
      <div className="">
        <h3 className="font-bold text-[.9rem]">{name}</h3>
        {type === "special" ? (
          <h3 className="font-bold text-[.8rem]">{price} FRCFA</h3>
        ) : null}
      </div>
      {/* <h3 className="font-bold text-[.8rem]">{weight} kg</h3> */}

      <div className="flex items-center gap-2">
        <div
          className="w-[30px] h-[30px] rounded-full bg-[#4BC6AB] flex items-center justify-center cursor-pointer"
          onClick={() => onAddOrder("moins", id, weight, price!, article)}
        >
          <Minus color="#fff" size={"1rem"} />
        </div>
        <span className="font-bold text-[1rem]">{quantity ? quantity : 0}</span>
        <div
          className="w-[30px] h-[30px] rounded-full bg-[#4BC6AB] flex items-center justify-center cursor-pointer"
          onClick={() => onAddOrder("add", id, weight, price!, article)}
        >
          <Plus color="#fff" size={"1rem"} />
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
