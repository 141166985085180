import { useSelector } from "react-redux";
import { getSlogan } from "../store/features/orderSlice";

function useGetCurrentService() {
  const slogan = useSelector(getSlogan);

  return slogan;
}

export default useGetCurrentService;
