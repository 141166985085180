import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import API, { API_URL } from "../../routes/api.routes";
import { logout, setAccessToken } from "../features/userSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }: any) => {
    const accessToken = getState().dataPersisted?.user.accessToken;

    if (accessToken) {
      return headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: any = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  let result: any = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const refreshToken = api.getState().dataPersisted?.user.refreshToken;
    try {
      const refreshResult: any = await baseQuery(
        {
          url: API.USER_API.REFRESH_TOKEN(),
          method: "POST",
          body: { refreshToken },
        },
        api,
        extraOptions
      );
      if (refreshResult?.data) {
        api.dispatch(setAccessToken(refreshResult.data.access_token));
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    } catch (error) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  keepUnusedDataFor: 86400,
  reducerPath: "FotFatFat",
  endpoints: () => ({}),
});

export const ApiMananger = baseApi.enhanceEndpoints({
  addTagTypes: ["Users", "Categories", "Orders", "Services"],
});
