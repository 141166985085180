import React from "react";
import { Template } from "../../layouts/Template";
import { useGetUserNotificationsQuery } from "../../store/features/userApi";
import RenderData from "../../components/renderData";
import Divider from "../../components/divider";
import TopBar from "../../components/topBar";

function Notifications() {
  const { data, isLoading } = useGetUserNotificationsQuery({});

  return (
    <Template
      topBar={<TopBar title="Liste des notifications" />}
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.03)" }}
    >
      <div className="p-2 flex flex-col gap-4">
        <RenderData
          data={data}
          isLoading={isLoading}
          renderEmpty={"pas de notifications"}
          render={(item: any) => (
            <div
              key={item?.id}
              className={`border-[1px] border-gray p-4 rounded-xl flex flex-col gap-1 shadow-lg ${
                !item?.isRead ? "bg-white" : null
              }`}
            >
              <h3 className="font-bold">{item?.title}</h3>
              <p className="text-sm">{item?.body}</p>
              <Divider />
              <p className="text-right text-sm">
                {new Date(`${item?.createdAt}`).toLocaleDateString("fr", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </p>
            </div>
          )}
        />
      </div>
    </Template>
  );
}

export default Notifications;
