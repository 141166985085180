import React from "react";
import { NavLink } from "react-router-dom";

function TabLink({ data }: { data: { href: string; label: string }[] }) {
  return (
    <div className="flex justify-around">
      {data.map((item, index) => (
        <NavLink
          key={index}
          to={item?.href}
          className={({ isActive }) =>
            `w-1/2 border-b-2 ${
              isActive ? "border-third" : "border-gray"
            } flex justify-center p-3`
          }
          end
        >
          {({ isActive }) => (
            <h2
              className={`text-[.9rem] font-bold text-${
                isActive ? "third" : "black"
              }`}
            >
              {item?.label}
            </h2>
          )}
        </NavLink>
      ))}
    </div>
  );
}

export default TabLink;
