import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useGetAuthenticate from "../../Hooks/useGetAuthenticate";

function WithoutAuth() {
  const isAuthenticated = useGetAuthenticate();

  const location = useLocation();

  return !isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={"/accueil"} state={{ from: location }} replace />
  );
}

export default WithoutAuth;
