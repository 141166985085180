import React from "react";
import { Outlet } from "react-router-dom";
import TabLink from "../../components/tabLink";

function Auth() {
  return (
    <div className="bg-primary h-screen p-5">
      <h1 className="text-center text-[2rem] font-[900] text-third">
        Fot Fat Fat
      </h1>

      <div className="mt-5">
        <TabLink
          data={[
            { href: "sign-in", label: "Connexion" },
            { href: "sign-up", label: "Inscription" },
          ]}
        />
        <div className="my-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Auth;
