import API from "../../routes/api.routes";
import { ApiMananger } from "../app/apiMananger";

const articleApi = ApiMananger.injectEndpoints({
  endpoints: (build: any) => ({
    getAllArticles: build.query({
      query: () => API.ARTICLE.GETALL(),
      providesTags: ["Categories"],
    }),
    getAllSimpleArticles: build.query({
      query: () => API.ARTICLE.GETALLWITHQUERY("?type=simple"),
      providesTags: ["Categories"],
    }),
    getAllSpecialArticles: build.query({
      query: () => API.ARTICLE.GETALLWITHQUERY("?type=special"),
      providesTags: ["Categories"],
    }),
    getAllArticlesByCategory: build.query({
      query: (id: string) => API.ARTICLE.GETALL_BY_CATEGORY(id),
      providesTags: ["Categories"],
    }),
    getArticle: build.query({
      query: (id: number) => API.ARTICLE.GET(id),
      providesTags: ["Categories"],
    }),
    // updatePassword: build.mutation({
    //   query(body: any) {
    //     return {
    //       url: "",
    //       method: "PUT",
    //       body,
    //     };
    //   },
    //   invalidatesTags: ["Categories"],
    // }),
  }),
});

export const {
  useGetAllArticlesQuery,
  useGetAllSimpleArticlesQuery,
  useGetAllSpecialArticlesQuery,
  useGetAllArticlesByCategoryQuery,
  useGetArticleQuery,
} = articleApi;
