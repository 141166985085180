import React from "react";
import { Template } from "../../../../layouts/Template";
import TopBar from "../../../../components/topBar";
import Button from "../../../../components/button";
import PricingModeCard from "../../../../components/PricingModeCard";

const data: { id: number; label: string; exp: string; image: string }[] = [
  { id: 1, label: "Wave", exp: "06/2024", image: "wave.png" },
  { id: 2, label: "Orange money", exp: "06/2024", image: "om.png" },
  { id: 3, label: "PayPal", exp: "06/2024", image: "paypal.png" },
  {
    id: 4,
    label: "Mastercard expirant",
    exp: "06/2024",
    image: "mc.png",
  },
  { id: 5, label: "Visa", exp: "06/2024", image: "visa.png" },
];

function Payement() {
  return (
    <Template
      topBar={<TopBar title="Procéder au paiement" />}
      topBarStyle={{ height: 60 }}
      mainStyle={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
      bottomBar={
        <div className="h-full flex items-center px-4">
          <Button title="PAYER - 25.000 FCFA" />
        </div>
      }
    >
      {data.map((item) => (
        <PricingModeCard key={item.id} {...item} />
      ))}
    </Template>
  );
}

export default Payement;
