import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "./store/app/Store";
import { PersistGate } from "redux-persist/integration/react";

import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";

import Rooter from "./Rooter";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Rooter />
          <Toaster position="bottom-center" reverseOrder={false} />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            style={{
              width: "100%",
              margin: "10px",
              padding: "0 20px",
            }}
          />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
