import React from "react";
import { X } from "tabler-icons-react";
import Button from "../../../../../../components/button";
import { useNavigate } from "react-router-dom";

function CancelPayment() {
  const navigation = useNavigate();
  return (
    <div className="w-full h-full bg-secondary text-white flex items-center justify-center flex-col relative">
      <div className="p-4 flex items-center justify-center flex-col">
        <div className="bg-[#EBF8E8] p-[11px] rounded-full mb-10">
          <div className="bg-[#df3020bc] p-[11px] rounded-full">
            <div className="bg-[#df3020] p-[11px] rounded-full">
              <X />
            </div>
          </div>
        </div>

        <p className="text-center text-[1rem] font-bold">
          <span className="text-xl">Payment Annule</span> <br /> Votre commande
          a été Annule
        </p>
      </div>

      <div className="absolute bottom-0 left-0 w-full h-20 p-4">
        <Button
          title="RETOUR À LA PAGE D’ACCUEIL "
          onClick={() => navigation("/accueil")}
          style={{ backgroundColor: "#fff", color: "#246084" }}
        />
      </div>
    </div>
  );
}

export default CancelPayment;
