import API from "../../routes/api.routes";
import { ApiMananger } from "../app/apiMananger";

interface PushSubscription {
  endpoint: string;
  expirationTime: number | null;
  keys: {
    p256dh: string;
    auth: string;
  };
}

const pushApi = ApiMananger.injectEndpoints({
  endpoints: (build: any) => ({
    pushSubscription: build.mutation({
      query(subscription: PushSubscription) {
        return {
          url: API.USER_API.PUSH_REGISTER(),
          method: "POST",
          body: subscription,
        };
      },
      invalidatesTags: ["PushSubscription"],
    }),
  }),
});

export const { usePushSubscriptionMutation } = pushApi;
export default pushApi;
