import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Template } from "../../layouts/Template";
import useGetAuthenticate from "../../Hooks/useGetAuthenticate";

function RequireAuth() {
  const isAuthenticated = useGetAuthenticate();

  const location = useLocation();

  return isAuthenticated ? (
    <Template
      bottomBarStyle={{ backgroundColor: "#fff" }}
      mainStyle={{
        backgroundColor: "#fff",
      }}
    >
      <Outlet />
    </Template>
  ) : (
    <Navigate to={"/splash"} state={{ from: location }} replace />
  );
}

export default RequireAuth;
