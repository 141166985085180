import React, { useEffect, useState } from "react";
import { Template } from "../../../../../../layouts/Template";
import TopBar from "../../../../../../components/topBar";
import Button from "../../../../../../components/button";
import Divider from "../../../../../../components/divider";
import InputDate from "../../../../../../components/inputDate";
import toast from "react-hot-toast";
import {
  getDate,
  getHour,
  getOrderPrice,
  setCollectHour,
} from "../../../../../../store/features/orderSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateTimeSlots } from "../../../../../../utils/getHours";
import { getDifferenceDate } from "../../../../../../utils/getDifirenceDate";

function Repassage() {
  const navigation = useNavigate();

  const dispatch = useDispatch();

  const dateStored = useSelector(getDate);
  const hourStored = useSelector(getHour);
  const OderPrice = useSelector(getOrderPrice);

  const [selectedDay, setSelectedDay] = useState("");
  const [selectedHour, setSelectedHour] = useState<string>("");

  const [hours] = useState(generateTimeSlots());

  useEffect(() => {
    if (hourStored) {
      const date = new Date(hourStored);
      setSelectedDay(
        `${date?.getFullYear()}-${date?.getMonth()! > 10 ? "" : "0"}${
          date?.getMonth()! + 1
        }-${date?.getDate() > 10 ? "" : "0"}${date?.getDate()}`
      );
    }
  }, [hourStored]);

  return (
    <Template
      topBar={<TopBar title="Créneaux de livraison" />}
      topBarStyle={{ height: 60 }}
      mainStyle={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      bottomBar={
        <div className="h-full flex items-center px-4 pb-2">
          <Button
            title={`CONTINUER`}
            onClick={(e) => {
              e.preventDefault();

              if (selectedDay && selectedHour) {
                if (!getDifferenceDate(dateStored!, new Date(selectedDay))) {
                  return toast.error(
                    "La commande doit etre livree au plutot dans deux jours, selectionner une autre date"
                  );
                }
                dispatch(
                  setCollectHour(new Date(`${selectedDay} ${selectedHour}`))
                );
                navigation("/accueil/commande");
              } else {
                return toast.error("Selectionner la date et l'heure");
              }
            }}
          />
        </div>
      }
    >
      <div className="w-full">
        <p className="text-[.9rem] mb-2">Choisir la date de ramassage</p>
        <InputDate
          value={selectedDay || ""}
          onChange={setSelectedDay}
          placeholder="Select a date"
        />
      </div>

      <Divider my={6} />
      <div className=" w-full">
        <p className="text-[.9rem]">Choisir l’heure de ramassage</p>
        <div className="grid gap-4 grid-cols-3 mt-4">
          {hours.map((hour) => (
            <div
              className={`${
                selectedHour === hour
                  ? "bg-green-500"
                  : "bg-gray hover:bg-green-100"
              } py-2 px-4 rounded-full text-center cursor-pointer `}
              key={hour}
              onClick={() => setSelectedHour(hour)}
            >
              <span className="text-[.8rem]">{hour}</span>
            </div>
          ))}
        </div>
      </div>
    </Template>
  );
}

export default Repassage;
