const { createSlice } = require("@reduxjs/toolkit");

const useSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    isSkeep: false,
  },
  reducers: {
    setAccessToken: (state: any, action: any) => {
      // state.isAuthenticated = true;
      state.accessToken = action.payload;
    },
    setRefreshToken: (state: any, action: any) => {
      state.refreshToken = action.payload;
    },
    setCredentials: (state: any, action: any) => {
      state.refreshToken = action.payload.refresh_token;
      state.accessToken = action.payload.access_token;
      state.isAuthenticated = true;
    },

    setSkeep(state: any) {
      state.isSkeep = true;
    },

    logout: (state: any) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
    },
  },
});

export const getUserStatus = (state: any) =>
  state?.dataPersisted?.user?.isAuthenticated;
export const getSkeep = (state: any) => state?.dataPersisted?.user?.isSkeep;

export const { setCredentials, setAccessToken, logout, setSkeep } =
  useSlice.actions;
export default useSlice.reducer;
