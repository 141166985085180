import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userIdReducer from "../features/userSlice";
import { baseApi } from "./apiMananger";
import orderReducer from "../features/orderSlice";

// Installer indexDB
const persistConfig = {
  key: "fffRoot",
  storage,
};

const rootReducer = combineReducers({
  dataPersisted: persistReducer(
    persistConfig,
    combineReducers({
      user: userIdReducer,
      oders: orderReducer,
    })
  ),
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([baseApi.middleware]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
