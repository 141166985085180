import React from "react";
import Input from "../../../components/input";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import Button from "../../../components/button";
import { ArrowLeft } from "tabler-icons-react";
import { useForgetPasswordMutation } from "../../../store/features/userApi";
import toast from "react-hot-toast";

function ForgetPassword() {
  const navigation = useNavigate();

  const [FORGET_PASSWOR, { isLoading }] = useForgetPasswordMutation({});

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSendEmail = async (email: string) => {
    try {
      const res: any = await FORGET_PASSWOR({ email });
      if (!res.error) {
        toast.success("Un mail de réinitialisation vous a été envoyé", {
          duration: 10000,
        });

        return navigation(-1);
      }
      return toast.error("Une erreur c'est produite");
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="w-full h-full p-3 bg-white">
      <div className="p-2  mb-3 w-40" onClick={() => navigation(-1)}>
        <ArrowLeft size={40} />
      </div>
      <h1 className="font-bold">Mot de passe oublie</h1>

      <div className="my-6 text-[.8rem]">
        <p>Content de te revoir! Vous n'avez pas de compte ?</p>
        <Link to={"/auth/sign-up"} className="text-third">
          S'inscrire
        </Link>
      </div>
      <form onSubmit={form.onSubmit((value) => handleSendEmail(value.email))}>
        <Input
          name="email"
          type="email"
          placeholder="mettez votre email"
          {...form.getInputProps("email")}
          error={form.errors?.email}
          style={{ marginBottom: 30 }}
        />

        <Button
          title="recupere le mot de passe"
          type="submit"
          loading={isLoading}
        />
      </form>
    </div>
  );
}

export default ForgetPassword;
