import { ThemeInterface } from "../declarations";

const THEMES: ThemeInterface = {
  colors: {
    primary: "#246084",
    secondary: ["#4BC6AB", "#8A8D9F", "#F9F9FF", "#FFFFFF"],
  },
  theme: {
    dark: {
      bg: "#000000",
      color: "#ffffff",
    },
    light: {
      bg: "#ffffff",
      color: "#000000",
    },
  },
};

export default THEMES;

export const SERVICES: {
  name: string;
  slogan: "LS" | "LSP" | "LSR" | "R" | "S";
  image: string;
  prix: number;
  minPrice?: number;
  transport?: number;
}[] = [
  {
    name: "Lavage Sechage",
    slogan: "LS",
    image: "2.png",
    prix: 700,
    minPrice: 3500,
    transport: 2000,
  },
  {
    name: "Lavage Sechage Pilage",
    slogan: "LSP",
    image: "1.png",
    prix: 800,
    minPrice: 4000,
    transport: 2000,
  },
  {
    name: "Lavage Sechage Repassage",
    slogan: "LSR",
    image: "3.png",
    prix: 900,
    minPrice: 4500,
    transport: 2000,
  },
  {
    name: "Repassage",
    slogan: "R",
    image: "4.png",
    prix: 250,
    transport: 2000,
  },
  {
    name: "Lavage Special",
    slogan: "S",
    image: "4.png",
    prix: 250,
    transport: 2000,
  },
];

export const PAYMENTMETODEDATA: {
  id: number;
  label: string;
  exp: string;
  image: string;
}[] = [
  { id: 1, label: "Wave", exp: "06/2024", image: "wave.png" },
  { id: 2, label: "Orange money", exp: "06/2024", image: "om.png" },
  { id: 3, label: "PayPal", exp: "06/2024", image: "paypal.png" },
  {
    id: 4,
    label: "Mastercard expirant",
    exp: "06/2024",
    image: "mc.png",
  },
  { id: 5, label: "Visa", exp: "06/2024", image: "visa.png" },
];
