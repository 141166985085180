import { createSlice } from "@reduxjs/toolkit";
import { OderItem } from "../../setup/declarations";
import { SERVICES } from "../../setup/constants";

export interface OrderSlice {
  orders: OderItem[];
  collectionTime?: Date;
  deliveryTime?: Date;
  weigth: number;
  number: number;
  serviceId: number | null;
  price?: number;
  priceExpress?: number;
  slogan?: "LS" | "LSP" | "LSR" | "R" | "S";
}

// Define the initial state using that type
const initialState: OrderSlice = {
  orders: [],
  collectionTime: new Date(Date.now()),
  deliveryTime: new Date(Date.now()),
  weigth: 0,
  number: 0,
  price: 0,
  priceExpress: 0,
  serviceId: null,
};

interface Payload {
  id: string;
  type: "add" | "moins";
  weight: number;
  article?: any;
}

interface PayloadSpecial {
  id: string;
  type: "add" | "moins";
  price: number;
  article?: any;
}

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrder(state: OrderSlice, { payload }: { payload: Payload }) {
      // check if oder exist
      const index = state.orders.findIndex(
        (item) => item.articleId === payload.id
      );

      if (index !== -1) {
        // check action type
        switch (payload.type) {
          case "moins":
            if (state.orders[index].quantity === 1) {
              state.orders[index].quantity--;
              state.weigth = state.weigth - payload.weight;
              state.orders = state.orders.filter(
                (order) => order.articleId !== state.orders[index].articleId
              );
            } else {
              state.orders[index].quantity--;
              state.weigth = state.weigth - payload.weight;
            }

            break;
          case "add":
            state.orders[index].quantity++;
            state.weigth = state.weigth + payload.weight;
            break;
        }

        // state.number = state.orders.length;
        state.number = state.orders.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );
      } else {
        if (payload.type === "add") {
          if (state.orders.length > 0) {
            state.weigth = state.weigth + payload.weight;
          } else {
            state.weigth = payload.weight;
          }
          state.orders = [
            ...state.orders,
            {
              articleId: payload.id,
              quantity: 1,
              description: "test decription",
              weight: payload.weight,
              article: payload.article,
            },
          ];
        }
      }
    },

    addSpecialOrder(
      state: OrderSlice,
      { payload }: { payload: PayloadSpecial }
    ) {
      // check if oder exist
      const index = state.orders.findIndex(
        (item) => item.articleId === payload.id
      );

      if (index !== -1) {
        // check action type
        switch (payload.type) {
          case "moins":
            if (state.orders[index].quantity === 1) {
              // state.weigth = state.weigth - payload.weight;
              state.orders = state.orders.filter(
                (order) => order.articleId !== state.orders[index].articleId
              );
            } else {
              state.orders[index].quantity--;
            }

            break;
          case "add":
            state.orders[index].quantity++;
            break;
        }

        // state.number = state.orders.length;
        state.number = state.orders.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );
      } else {
        if (payload.type === "add") {
          state.orders = [
            ...state.orders,
            {
              articleId: payload.id,
              quantity: 1,
              description: "test decription",
              price: payload.price,
              weight: 0,
              article: payload.article,
            },
          ];
        }
      }
    },

    setSpecialPrice(state: OrderSlice) {
      let price = 0;

      for (let i = 0; i < state.orders.length; i++) {
        let orderPrice = state.orders[i].quantity * state.orders[i].price!;
        price += orderPrice;
      }

      state.price = price;
    },

    setPrice(state: OrderSlice) {
      // state.price = Math.round(state.weigth) > 5 ? state.weigth * 1000 : 5000;
      if (state.slogan) {
        const service = SERVICES.find(
          (service) => service.slogan === state.slogan
        );

        if (!service) {
          state.price = 0;
          return;
        }

        if (service.slogan.toUpperCase() === "R") {
          state.price = state.number * service.prix!;
          return;
        }

        state.price =
          state.weigth! * service?.prix > service?.minPrice!
            ? state.weigth! * service?.prix
            : service?.minPrice!;
      } else {
        state.price = 0;
      }
    },

    setExpressPrice(
      state: OrderSlice,
      { payload }: { payload: { type: "Express24" | "Express48" } }
    ) {
      let price = 0;

      if (payload.type === "Express24") {
        if (state.priceExpress! > state.price!) {
          return;
        }

        if (state.slogan === "R" || state.slogan === "S") {
          const quantity =
            state.number ||
            state?.orders.reduce((accu, curr) => accu + curr.quantity, 0);

          price = quantity * 100 + state.price!;
        } else {
          price = state.weigth * 250 + state.price!;
        }
      } else {
        if (state.priceExpress! >= state.price!) {
          price = state.price!;
        }
      }

      state.priceExpress = price;
    },

    setWeight(
      state,
      { payload }: { payload: { number: number; weight: number } }
    ) {
      // console.log({ payload });
      if (state.slogan !== "R") {
        state.weigth = payload.weight;
      }
      state.number = payload.number;
      state.orders = [];
    },

    setService(state, { payload }: { payload: { id: number } }) {
      // console.log({ payload });
      state.serviceId = payload.id;
    },

    setCollectDate(state, { payload }) {
      state.collectionTime = payload;
    },

    setCollectHour(state, { payload }) {
      state.deliveryTime = payload;
    },

    setSlogan(state, { payload }) {
      state.slogan = payload;
    },

    claerOrders: (state: OrderSlice) => {
      state.orders = [];
      state.collectionTime = new Date(Date.now());
      state.deliveryTime = new Date(Date.now());
      state.weigth = 0;
      state.number = 0;
      state.price = 0;
    },
  },
});

export const getDate = (state: { dataPersisted: { oders: OrderSlice } }) =>
  state?.dataPersisted?.oders?.collectionTime;
export const getSlogan = (state: { dataPersisted: { oders: OrderSlice } }) =>
  state?.dataPersisted?.oders?.slogan;
export const getHour = (state: { dataPersisted: { oders: OrderSlice } }) =>
  state?.dataPersisted?.oders?.deliveryTime;
export const getOrderInfo = (state: { dataPersisted: { oders: OrderSlice } }) =>
  state?.dataPersisted?.oders;
export const getServiceId = (state: { dataPersisted: { oders: OrderSlice } }) =>
  state?.dataPersisted?.oders?.serviceId;
export const getOrderPrice = (state: {
  dataPersisted: { oders: OrderSlice };
}) => state?.dataPersisted?.oders?.price;
export const getOrderPriceExpress = (state: {
  dataPersisted: { oders: OrderSlice };
}) => state?.dataPersisted?.oders?.priceExpress;

export const {
  addOrder,
  addSpecialOrder,
  setWeight,
  setService,
  claerOrders,
  setCollectDate,
  setCollectHour,
  setPrice,
  setExpressPrice,
  setSpecialPrice,
  setSlogan,
} = orderSlice.actions;
export default orderSlice.reducer;
