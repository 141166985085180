import React from "react";
// import { CardboardsOff } from "tabler-icons-react";
import { Order } from "../../setup/declarations";
import orderColors from "../../utils/orderColors";

function CommandeCard({ status, createdAt, orderNumber, price }: Order) {
  return (
    <div className="border-[1px] border-gray p-2 rounded-xl flex flex-col gap-5">
      <div className="flex justify-between">
        <p className="text-[.8rem]">{orderNumber}</p>
        <p className="text-[.8rem]">{price} fcfa</p>
      </div>

      <span
        className={`p-[5px] rounded-full ${
          orderColors.find((color) => color.name === status)?.value
        } text-[.7rem]`}
      >
        {status}
      </span>

      <div className="flex justify-between mt-2">
        <p className="text-[.8rem] text-[#808080c7]">
          {new Date(createdAt as Date).toLocaleDateString("fr", {
            day: "2-digit",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
        {/* <div className="flex gap-2">
          <p className="text-[.8rem] text-[red] font-bold">{status}</p>
          <p>
            <CardboardsOff />
          </p>
        </div> */}
      </div>
    </div>
  );
}

export default CommandeCard;
