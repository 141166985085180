const orderColors: {
  name:
    | "PENDING"
    | "REJECTED"
    | "ACCEPTED"
    | "DELIVRED"
    | "PROCESSING"
    | "CANCELED"
    | "SHIPPED";
  value: string;
}[] = [
  { name: "ACCEPTED", value: "bg-green-300" },
  { name: "DELIVRED", value: "bg-green-500" },
  { name: "PENDING", value: "bg-[#8080802c]" },
  { name: "REJECTED", value: "bg-red-500" },
  { name: "CANCELED", value: "bg-orange-500" },
  { name: "SHIPPED", value: "bg-green-700" },
  { name: "PROCESSING", value: "bg-orange-200" },
];

export default orderColors;
