import React from "react";
import Button from "../../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useEmailVerificationQuery } from "../../../store/features/userApi";

function VerifyEmail() {
  const navigation = useNavigate();

  const params = useParams<{ token: string }>();
  const token = params?.token || '';

  const { isLoading, isSuccess } = useEmailVerificationQuery(token);

  return (
    <div className="bg-primary h-screen p-5 flex items-center justify-center flex-col gap-8">
      <h1
        className={`text-center text-[1rem] font-[900] ${isSuccess ? "text-third" : "text-gray"
          } `}
      >
        {isLoading
          ? "Verification en cour...."
          : isSuccess
            ? "Email Verification Successfully"
            : "Email non verifier"}
      </h1>

      {!isLoading && isSuccess ? (
        <Button
          title="Se connecter"
          style={{ width: 150 }}
          onClick={() => {
            navigation("/auth/sign-in");
          }}
        />
      ) : null}
    </div>
  );
}

export default VerifyEmail;
