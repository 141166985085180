import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "tabler-icons-react";

function TopBar({
  title,
  leftItem,
}: {
  title: string;
  leftItem?: React.ReactNode | string;
}) {
  const navigation = useNavigate();
  return (
    <div className="h-full flex gap-4 items-center justify-between px-4 font-bold text-lg">
      <div className="flex gap-4 items-center">
        <ArrowLeft onClick={() => navigation(-1)} />
        <span className="text-[.9rem]">{title}</span>
      </div>
      {leftItem}
    </div>
  );
}

export default TopBar;
