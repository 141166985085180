import React from "react";
import BottomTab from "../../components/bottomTab";
import { Template } from "../../layouts/Template";
import {
  Bell,
  // Heart,
  // ChevronRight,
  // History,
  // Moon,
  // Language,
  User,
  UserCircle,
  Phone,
  // Location,
  At,
} from "tabler-icons-react";
import Button from "../../components/button";
import { useDispatch } from "react-redux";
import { logout } from "../../store/features/userSlice";
import { useGetUserQuery } from "../../store/features/userApi";
import { User as IUser } from "../../setup/declarations";
import RenderData from "../../components/renderData";
import { Link } from "react-router-dom";

function Compte() {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetUserQuery<{
    data: IUser;
    isLoading: boolean;
  }>({});

  // console.log({ data });

  const handleLogout = async () => {
    await dispatch(logout());
  };

  return (
    <Template
      bottomBar={<BottomTab />}
      topBar={
        <div className="p-6 pt-4 relative">
          <div className="flex justify-between items-center ">
            <h1 className="font-bold">Mon Profil</h1>
            <Link to={"/notifications"}>
              <Bell size={28} strokeWidth={1.5} />
            </Link>
          </div>
        </div>
      }
    >
      <div className="flex justify-center items-end h-20 mt-10">
        <div className="w-24 h-24 rounded-full border-2 border-gray flex items-center justify-center bg-white">
          <img
            src={"assets/logos/Fot_Fat_Fat_Blanc.png"}
            alt=""
            className="w-full border-4 border-white h-full rounded-full object-cover"
          />
        </div>
      </div>

      <div className="flex justify-center mt-4 ">
        <Link to={"/profile/edit"}>

          <Button title="éditer le profil" style={{ width: 150 }} />
        </Link>
      </div>
      <div className="flex flex-col ">
        <div className="flex h-auto  flex-col justify-between  pt-5 ">
          <div className="px-4">
            <p className="font-bold">Informations</p>
            <RenderData
              data={data}
              isLoading={isLoading}
              render={(user) => (
                <div className="flex flex-col gap-2 mt-2">
                  <div className="flex items-center gap-2">
                    <User size={15} />
                    <p className="text-[.8rem]">{user?.username}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <Phone size={15} />
                    <p className="text-[.8rem]">+221 {user?.phoneNumber}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <At size={15} />
                    <p className="text-[.8rem]"> {user?.email}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <UserCircle size={15} />
                    <p className="text-[.8rem]">{user.fullName}</p>
                  </div>
                </div>
              )}
            />
          </div>

          {/* <div className="w-full h-[2px] bg-gray my-5"></div>

          <div className="px-4">
            <p className="font-bold">CONTENU</p>
            <div className="flex flex-col gap-2 mt-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Heart size={15} />
                  <p className="text-[.8rem]">Favoris</p>
                </div>
                <ChevronRight size={14} />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <History size={15} />
                  <p className="text-[.8rem]">Historique</p>
                </div>
                <ChevronRight size={14} />
              </div>
            </div>
          </div>

          <div className="w-full h-[2px] bg-gray my-5"></div>

          <div className="px-4">
            <p className="font-bold">PREFERENCE</p>
            <div className="flex flex-col gap-2 mt-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Language size={15} />
                  <p className="text-[.8rem]">Languages</p>
                </div>
                <ChevronRight size={14} />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Moon size={15} />
                  <p className="text-[.8rem]">DarkMode</p>
                </div>
                <ChevronRight size={14} />
              </div>
            </div>
          </div> */}

          <div className=" w-full flex flex-col py-1 px-3 mt-5 absolute bottom-[10px]">
            <Button title="Déconnexion" onClick={handleLogout} />
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col ">
        <div className=" flex justify-center mt-4 w-[150px] self-center">
          <Button title="éditer le profil" />
        </div>
        <div className="flex h-auto  flex-col justify-between  pt-5 ">
          <p className="bg-slate-200 px-3">Titre</p>
          <div className="p-3 ">
            <p className="bg-white"> Mouhamed Badianne</p>
            <p className="bg-white"> +221 77 777 77 78</p>
            <p className="bg-white"> Parcelles Assainies Unite 15</p>
          </div>
          <p className="bg-slate-200 px-3">Contenu</p>
          <div className="p-3 ">
            <p className="bg-white flex pb-2">
              <span className="me-3">
                <Heart />
              </span>{" "}
              Favoris
              <span className="ms-auto">
                <ChevronRight />
              </span>
            </p>
            <p className="bg-white flex">
              <span className="me-3">
                <History />
              </span>{" "}
              Historique{" "}
              <span className="ms-auto">
                <ChevronRight />
              </span>
            </p>
          </div>
          <p className="bg-slate-200 px-3">Preference</p>
          <div className="p-3 ">
            <p className="bg-white flex pb-2">
              <span className="me-3">
                <Language />
              </span>{" "}
              Languages
              <span className="ms-auto">
                <ChevronRight />
              </span>
            </p>
            <p className="bg-white flex">
              <span className="me-3">
                <Moon />
              </span>{" "}
              DarkMode
              <span className="ms-auto">
                <ChevronRight />
              </span>
            </p>
          </div>
          <div className=" w-full flex flex-col py-5 px-3">
            <Button title="Déconnexion" />
          </div>
        </div>
      </div> */}
    </Template>
  );
}

export default Compte;
