export function generateTimeSlots(): string[] {
  const timeSlots: string[] = [];
  const startTime: Date = new Date();
  startTime.setHours(10, 0, 0, 0); // Définit l'heure de début à 10h00

  const endTime: Date = new Date();
  endTime.setHours(18, 0, 0, 0); // Définit l'heure de fin à 18h00

  let currentTime: Date = startTime;

  while (currentTime <= endTime) {
    const hours: string = currentTime.getHours().toString().padStart(2, "0");
    const minutes: string = currentTime
      .getMinutes()
      .toString()
      .padStart(2, "0");
    const formattedTime: string = `${hours}:${minutes}`;
    timeSlots.push(formattedTime);

    // Ajoute 30 minutes à l'heure actuelle
    currentTime = new Date(currentTime.getTime() + 30 * 60 * 1000);
  }

  return timeSlots;
}
