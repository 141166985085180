export function getDifferenceDate(
  dateDepart: Date,
  dateChoisie: Date
): boolean {
  const differenceEnMillisecondes =
    dateChoisie.getTime() - dateDepart.getTime();
  const differenceEnJours = differenceEnMillisecondes / (1000 * 60 * 60 * 24);
  //   console.log({ differenceEnJours });

  return differenceEnJours >= 1;
}
