import { Template } from "../../layouts/Template";

import Button from "../../components/button";
import { useGetUserQuery, useUpdateProfileMutation } from "../../store/features/userApi";
import { User as IUser } from "../../setup/declarations";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "@mantine/form";
import TopBar from "../../components/topBar";

function EditProfile() {

    const navigation = useNavigate();

    const { data, isLoading } = useGetUserQuery<{
        data: IUser;
        isLoading: boolean;
    }>({});

    const [updateProfile, { isLoading: loading }] = useUpdateProfileMutation()

    const form = useForm({
        initialValues: {
            username: data.username,
            email: data.email,
            fullName: data.fullName,
            phoneNumber: data.phoneNumber,
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
            username: (value) => (value ? null : "Veuillez remplir ce champs"),
            fullName: (value) => (value ? null : "Veuillez remplir ce champs"),
            phoneNumber: (value) => (value ? null : "Veuillez remplir ce champs"),
        },
    });


    const handleUpdate = form.onSubmit(async (values) => {
        try {
            const res: any = await updateProfile({
                userId: +data.id,
                body: {
                    fullName: values.fullName,
                    phoneNumber: values.phoneNumber
                }
            })
            if (!res.error) {
                toast.success("Votre profile a été modifié", {
                    duration: 10000,
                });

                return navigation(-1);
            }
            return toast.error("Une erreur c'est produite");
        } catch (error) {
            console.log({ error });
        }
    })

    return (
        <Template
            topBar={
                <TopBar
                    title={`Edit Profile`}
                />
            }

        >
            <div className="flex justify-center items-end h-20 mt-10">
                <div className="w-24 h-24 rounded-full border-2 border-gray flex items-center justify-center bg-white">
                    <img
                        src={"assets/logos/Fot_Fat_Fat_Blanc.png"}
                        alt=""
                        className="w-full border-4 border-white h-full rounded-full object-cover"
                    />
                </div>
            </div>

            <div className="flex flex-col ">
                <div className="flex h-auto  flex-col justify-between  pt-5 ">
                    <div className="px-4">
                        <p className="font-bold">Informations</p>
                        {
                            isLoading ? ("Chargement des informations") : (
                                <form className="mt-10" onSubmit={handleUpdate}>
                                    <div className="mb-8">
                                        <input
                                            disabled
                                            name="username"
                                            type="text"
                                            {...form.getInputProps("username")}
                                            placeholder="Nom d'utilisateur"
                                            className="border-gray border-2 hover:cursor-not-allowed focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
                                        />
                                        {form.errors.username && (
                                            <span className="text-[red] ml-4 text-[.8rem]">
                                                {form.errors.username}
                                            </span>
                                        )}
                                    </div>

                                    <div className="mb-8">
                                        <input
                                            name="fullName"
                                            type="text"
                                            {...form.getInputProps("fullName")}
                                            placeholder="Prenom et Nom"
                                            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
                                        />
                                        {form.errors.fullName && (
                                            <span className="text-[red] ml-4 text-[.8rem]">
                                                {form.errors.fullName}
                                            </span>
                                        )}
                                    </div>

                                    <div className="mb-8">
                                        <input
                                            name="phome"
                                            type="tel"
                                            {...form.getInputProps("phoneNumber")}
                                            placeholder="Numero de telephone"
                                            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
                                        />
                                        {form.errors.phoneNumber && (
                                            <span className="text-[red] ml-4 text-[.8rem]">
                                                {form.errors.phoneNumber}
                                            </span>
                                        )}
                                    </div>

                                    <div className="mb-8">
                                        <input
                                            disabled
                                            name="email"
                                            type="email"
                                            {...form.getInputProps("email")}
                                            placeholder="your@email.com"
                                            className="border-gray border-2 hover:cursor-not-allowed focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
                                        />
                                        {form.errors.email && (
                                            <span className="text-[red] ml-4 text-[.8rem]">
                                                {form.errors.email}
                                            </span>
                                        )}
                                    </div>


                                    <Button title="METTRE À JOUR" type="submit" loading={loading} />
                                </form>
                            )
                        }

                    </div>
                </div>
            </div>

        </Template >
    );
}

export default EditProfile;
