import React from "react";
import { Template } from "../../../../layouts/Template";
import TopBar from "../../../../components/topBar";
import { useGetOrderQuery } from "../../../../store/features/orderApi";
import { useParams } from "react-router-dom";
import RenderData from "../../../../components/renderData";
import { Order } from "../../../../setup/declarations";
import Divider from "../../../../components/divider";
import { Clock } from "tabler-icons-react";
import Button from "../../../../components/button";
import { usePaymentOrderMutation } from "../../../../store/features/paymentApi";
import toast from "react-hot-toast";
import orderColors from "../../../../utils/orderColors";

function CommandeDetails() {
  const params: any = useParams();

  // const navigate = useNavigate();

  const [paymentOrder, { isLoading: isPaymentLoading }] =
    usePaymentOrderMutation();
  const { data, isLoading } = useGetOrderQuery<{
    data: Order;
    isLoading: boolean;
  }>(params?.id);

  const handlePay = async () => {
    try {
      const res: any = await paymentOrder({
        id: parseInt(params?.id!),
        item_name: "item_name_" + data.orderNumber,
        item_price: data?.price?.toString(),
        ref_command: data.orderNumber,
        command_name: data.orderNumber,
      });

      // console.log({ res });

      if (res.data) {
        return window.open(res?.data?.redirect_url);
      }
      return toast.error("Payment Error");
    } catch (error) {
      toast.error("Error");
    }
  };

  return (
    <Template
      topBar={
        <TopBar
          title={isLoading ? "chargement..." : data && `${data?.orderNumber}`}
          leftItem={
            isLoading ? null : data ? (
              <span
                className={`px-[5px] rounded-full
                ${
                  orderColors.find((color) => color.name === data?.status)
                    ?.value
                }
                 text-[.7rem]`}
              >
                {data?.status}
              </span>
            ) : null
          }
        />
      }
      topBarStyle={{ height: 60 }}
      mainStyle={{ padding: "0px" }}
      bottomBar={
        isLoading ? null : data?.payment ? null : (
          <Button
            title="Proceder au paiement"
            onClick={handlePay}
            loading={isPaymentLoading}
          />
        )
      }
      bottomBarStyle={{
        padding: "0 10px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <RenderData
        data={data}
        isLoading={isLoading}
        render={(order) => (
          <div>
            <h1 className="text-center mb-4 font-bold text-[1.5rem]">
              {order?.price} FCFA
            </h1>

            <Divider />
            <div className="p-4">
              <div className="flex justify-between items-center mb-3">
                <h1 className="">Nombre d'articles</h1>
                <span className="font-bold text-lg">
                  {order?.amountArticle} articles
                </span>
              </div>

              <Divider />

              <div className="my-3">
                <span className="text-md font-bold">Creneau de ramassage</span>
                <div className="flex gap-3 items-center border-l-4 border-secondary pl-2">
                  <Clock size={16} />
                  <span className="">{order?.collectionTime}</span>
                </div>
              </div>

              <span className="text-md font-bold">
                Date et heure de la livraison
              </span>
              <div className="flex gap-3 items-center border-l-4 border-secondary pl-2">
                <Clock size={18} />
                <span className="">{order?.deliveryTime}</span>
              </div>
            </div>
          </div>
        )}
      />
    </Template>
  );
}

export default CommandeDetails;
