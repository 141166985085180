import API from "../../routes/api.routes";
import { ApiMananger } from "../app/apiMananger";

const serviceApi = ApiMananger.injectEndpoints({
  endpoints: (build: any) => ({
    getAll: build.query({
      query: () => API.SERVICE.GETALL(),
      providesTags: ["Services"],
    }),
    getOne: build.query({
      query: (id: string) => API.SERVICE.GET(id),
      providesTags: ["Services"],
    }),
  }),
});

export const { useGetAllQuery, useGetOneQuery } = serviceApi;
