import API from "../../routes/api.routes";
import { ApiMananger } from "../app/apiMananger";

const userApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: () => API.USER_API.GET_USER(),
      providesTags: ["Users"],
    }),
    getUserNotifications: build.query({
      query: () => API.USER_API.NOFICATIONS(),
    }),
    emailVerification: build.query({
      query: (token: string) => API.USER_API.VERIFICATION_EMAIL(token),
    }),
    loginUser: build.mutation({
      query(body: { username: string; password: string }) {
        return {
          url: API.USER_API.LOGIN(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    registerUser: build.mutation({
      query(body: any) {
        return {
          url: API.USER_API.REGISTER(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    forgetPassword: build.mutation({
      query(body: any) {
        return {
          url: API.USER_API.FORGET_PASSWORD(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    otpVerification: build.mutation({
      query(body: any) {
        return {
          url: API.USER_API.OTP_VERIFICATION(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    resetPassword: build.mutation({
      query(body: any) {
        return {
          url: API.USER_API.RESET_PASSWORD(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updatePassword: build.mutation({
      query(body: any) {
        return {
          url: "",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
    updateProfile: build.mutation<null, UpdateProfileMutation>({
      query({ body, userId }) {
        return {
          url: API.USER_API.EDIT_PROFILE(userId),
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Users"],
    }),
  }),
});

type UpdateProfileMutation = {
  userId: number;
  body: { fullName: string; phoneNumber: string | number };
};

export const {
  useGetUserQuery,
  useGetUserNotificationsQuery,
  useLoginUserMutation,
  useRegisterUserMutation,
  useUpdatePasswordMutation,
  useEmailVerificationQuery,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useOtpVerificationMutation,
  useUpdateProfileMutation,
} = userApi;
