import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  height: 100dvh;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    "header"
    "main"
    "footer";
  ${(props: any) =>
    `grid-template-rows: ${props.topBar ? "1fr" : "0fr"} 1fr ${
      props.topBar ? "1fr" : "0fr"
    };`}
`;

const Header = styled.div`
  grid-area: header;
  height: 50px;
  width: 100%;
`;

const Footer = styled.div`
  grid-area: footer;
  height: 60px;
  width: 100%;
`;

const Main = styled.div`
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

interface PropsTemplate {
  topBar?: React.ReactNode;
  bottomBar?: React.ReactNode;
  topBarStyle?: React.CSSProperties;
  mainStyle?: React.CSSProperties;
  bottomBarStyle?: React.CSSProperties;
  children: React.ReactNode;
}

export const Template = (props: PropsTemplate) => {
  return (
    <Container>
      {props.topBar && (
        <Header style={{ ...props.topBarStyle }}>{props.topBar}</Header>
      )}
      <Main
        style={{
          ...props.mainStyle,
        }}
      >
        {props.children}
      </Main>
      {props.bottomBar && (
        <Footer style={{ ...props.bottomBarStyle }}>{props.bottomBar}</Footer>
      )}
    </Container>
  );
};
