import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllSpecialArticlesQuery } from "../../../../store/features/articleApi";
import { Search } from "tabler-icons-react";
import RenderData from "../../../../components/renderData";
import ArticleCard from "../../components/ArticleCard";
import Button from "../../../../components/button";
import { Template } from "../../../../layouts/Template";
import TopBar from "../../../../components/topBar";
import { useDispatch } from "react-redux";
import {
  addSpecialOrder,
  setSpecialPrice,
} from "../../../../store/features/orderSlice";
import useGetOders from "../../../../Hooks/useGetOders";
import { OderItem } from "../../../../setup/declarations";

function LavageSpecial() {
  const navigation = useNavigate();

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const { orders, price } = useGetOders();

  const { data, isLoading } = useGetAllSpecialArticlesQuery<{
    data: {
      articles: { name: string; weight: number; id: string; price: number }[];
    };
    isLoading: boolean;
  }>({});

  const handleAddOrder = (
    type: "add" | "moins",
    id: string,
    weight: number,
    price: number,
    article: any
  ) => {
    dispatch(addSpecialOrder({ type, id, price, article }));
    dispatch(setSpecialPrice());
  };

  return (
    <Template
      topBar={<TopBar title={"Lavage Special"} />}
      topBarStyle={{ height: 60 }}
      mainStyle={{ padding: "10px" }}
      bottomBar={
        <div className="bottom-0 left-0 w-full h-16 p-4 bg-[#fff]">
          <Button
            title={`Continuer - ${price} FRCFA`}
            onClick={() => navigation("/accueil/creneau")}
            disabled={orders.length === 0}
          />
        </div>
      }
    >
      <div className="flex flex-col gap-4 relative ">
        <div className="flex gap-4 border-[1px] border-[#c9c9c9] h-[40px] items-center px-3 rounded-full">
          <Search color="#c9c9c9" />
          <input
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Rechercher un article"
            type="text"
            name="search"
            id="search"
            className="h-full flex-1 outline-0"
          />
        </div>

        <p className="text-[.9rem]">Selectionnez les articles à laver.</p>

        <div className="flex flex-col gap-3 mb-20">
          <RenderData
            isLoading={isLoading}
            data={
              data
                ? data.articles.filter((value) =>
                    value.name.toLowerCase().includes(search.toLowerCase())
                  )
                : []
            }
            render={(article) => (
              <ArticleCard
                type="special"
                article={article}
                key={article?.id}
                {...article}
                onAddOrder={handleAddOrder}
                quantity={
                  orders.find(
                    (order: OderItem) => order.articleId === article.id
                  )
                    ? orders.find(
                        (order: OderItem) => order.articleId === article.id
                      )!.quantity
                    : 0
                }
              />
            )}
          />
        </div>
      </div>
    </Template>
  );
}

export default LavageSpecial;
