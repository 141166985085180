import React from "react";
import Button from "../../../components/button";
import { useRegisterUserMutation } from "../../../store/features/userApi";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function SignUp() {
  const [REGISTER, { isLoading }] = useRegisterUserMutation();
  const navigation = useNavigate();

  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      password: "",
      fullName: "",
      // age: "",
      // phoneNumber: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      username: (value) => (value ? null : "Veuillez remplir ce champs"),
      fullName: (value) => (value ? null : "Veuillez remplir ce champs"),
      password: (value) => (value ? null : "Veuillez remplir ce champs"),
      // age: (value) => (value ? null : "Veuillez remplir ce champs"),
      // phoneNumber: (value) => (value ? null : "Veuillez remplir ce champs"),
    },
  });

  const handleSignUp = form.onSubmit(async (values) => {
    try {
      const res: any = await REGISTER({
        ...values,
      });
      // console.log({ res });

      if (!res?.data) {
        if (res?.error?.data.message) {
          return toast.error(res.error.data.message);
        }

        return toast.error("Une erreur c'est produite l'ors de l'inscription");
      }
      form.reset();
      toast.success("Un Email vous a ete envoyer pour valider votre compte");
      navigation("/auth/sign-in", { replace: true });
    } catch (error) {
      throw new Error(`${error}`);
    }
  });

  return (
    <div>
      <form className="mt-10" onSubmit={handleSignUp}>
        <div className="mb-8">
          <input
            name="username"
            type="text"
            {...form.getInputProps("username")}
            placeholder="Nom d'utilisateur"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.username && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.username}
            </span>
          )}
        </div>

        <div className="mb-8">
          <input
            name="fullName"
            type="text"
            {...form.getInputProps("fullName")}
            placeholder="Prenom et Nom"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.email && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.fullName}
            </span>
          )}
        </div>

        {/* <div className="mb-8">
          <input
            name="phome"
            type="tel"
            {...form.getInputProps("phoneNumber")}
            placeholder="Numero de telephone"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.phoneNumber && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.phoneNumber}
            </span>
          )}
        </div> */}

        <div className="mb-8">
          <input
            name="email"
            type="email"
            {...form.getInputProps("email")}
            placeholder="your@email.com"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.email && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.email}
            </span>
          )}
        </div>

        {/* <div className="mb-8">
          <input
            name="age"
            type="number"
            {...form.getInputProps("age")}
            placeholder="Age"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.age && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.age}
            </span>
          )}
        </div> */}

        <div className="mb-8">
          <input
            name="password"
            type="password"
            {...form.getInputProps("password")}
            placeholder="Mot de passe"
            className="border-gray border-2 focus:border-third focus:outline-none w-full rounded-full px-4 h-[40px]"
          />
          {form.errors.password && (
            <span className="text-[red] ml-4 text-[.8rem]">
              {form.errors.password}
            </span>
          )}
        </div>

        <Button title="CREER UN COMPTE" type="submit" loading={isLoading} />
      </form>
    </div>
  );
}

export default SignUp;
