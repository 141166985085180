import React from "react";

function Logo() {
  return (
    <img
      src="assets/logos/Fot_Fat_Fat_transparent.png"
      alt="logo"
      className="w-9"
    />
  );
}

export default Logo;
