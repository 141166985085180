// export const API_URL = "https://api-fot.kazysport.com/api/v1";
export const API_URL = "https://api.fotfatfat.com/api/v1";
// export const API_URL = "http://localhost:5000/api/v1";

const generateApiPath = (basePath: string) => {
  return {
    GET: (id: string | number): string => `${basePath}/${id}`,
    GETALL: (): string => basePath,
    ADD: (): string => basePath,
    EDIT: (id: string | number): string => `${basePath}/${id}`,
    DELETE: (id: string | number): string => `${basePath}/${id}`,
  };
};

const USER_PATH = "auth";
const API = {
  USER_API: {
    LOGIN: () => USER_PATH + "/signin",
    PUSH_REGISTER: () => "/notifications/subscribe",
    REGISTER: () => USER_PATH + "/signup",
    REFRESH_TOKEN: () => USER_PATH + "/refresh",
    OTP_VERIFICATION: () => USER_PATH + "/verify-otp",
    FORGET_PASSWORD: () => USER_PATH + "/reset-password",
    RESET_PASSWORD: () => USER_PATH + "/reset-password-confirmation",
    VERIFICATION_EMAIL: (token: string) => USER_PATH + "/verify-email/" + token,
    GET_USER: () => "user/me",
    LOGOUT: () => USER_PATH + "/logout",

    NOFICATIONS: () => "user/notifications",
    EDIT_PROFILE: (userId: number) => `user/${userId}/edit`,
  },

  CATEGORY_API: {
    ...generateApiPath("category"),
  },

  ARTICLE: {
    ...generateApiPath("article"),
    GETALL_BY_CATEGORY: (id: string) => "article/category/" + id,
    GETALLWITHQUERY: (query: string) => `article${query}`,
  },

  ORDER: {
    ...generateApiPath("order"),
    USER: (): string => "user/orders",
  },

  PAYMENT: {
    ...generateApiPath("payment"),
    ADDORDER: (id: number): string => `payment/${id}`,
  },

  SERVICE: {
    ...generateApiPath("service"),
  },
};

export default API;
