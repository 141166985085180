import React from "react";
import { Template } from "../../layouts/Template";
import Logo from "../../components/logo";
import BottomTab from "../../components/bottomTab";
import { useNavigate } from "react-router-dom";
// import { useGetAllCategoriesQuery } from "../../store/features/categoryApi";
import RenderData from "../../components/renderData";
import { useGetAllQuery } from "../../store/features/serviceApi";
import { Service } from "../../setup/declarations";
import {
  claerOrders,
  setService,
  setSlogan,
} from "../../store/features/orderSlice";
import { useDispatch } from "react-redux";
import { SERVICES } from "../../setup/constants";
import Divider from "../../components/divider";

function Accueil() {
  const navigation = useNavigate();

  const dispatch = useDispatch();

  const { data, isLoading } = useGetAllQuery<{
    data: Service[];
    isLoading: boolean;
  }>({});

  return (
    <Template
      bottomBar={<BottomTab />}
      topBar={
        <div className="flex gap-4 h-24 items-center px-5 ">
          <Logo />
          <p className="text-[1rem] font-bold">Faire une demande</p>
        </div>
      }
      topBarStyle={{ height: 80 }}
      mainStyle={{ padding: "10px" }}
    >
      <div className="grid grid-cols-2 gap-2 mt-1">
        <RenderData
          isLoading={isLoading}
          data={data}
          render={(service) => (
            <CategorieCard
              key={service?.id}
              {...service}
              slogan={service?.name}
              name={
                SERVICES.find((_service) => _service.slogan === service.name)
                  ?.name!
              }
              image={
                SERVICES.find((_service) => _service.slogan === service.name)
                  ?.image!
              }
              onClick={() => {
                dispatch(claerOrders());
                dispatch(setService({ id: service.id }));
                dispatch(setSlogan(service.name));
                const name: string = SERVICES.find(
                  (_service) => _service.slogan === service.name
                )?.name!;
                navigation(
                  service?.name === "S" ? "special" : `${service?.id}`,
                  {
                    state: { name },
                  }
                );
              }}
            />
          )}
        />
      </div>
      {/* <Divider my={"my-6"} />

      <RenderData
        data={{ name: "LSPE" }}
        render={(service) => (
          <CategorieCard
            {...service}
            name={"Lavage Special"}
            image={""}
            onClick={() => {
              dispatch(claerOrders());
              // dispatch(setService({ id: service.id }));
              // dispatch(setSlogan(service.name));
              // const name: string = SERVICES.find(
              //   (_service) => _service.slogan === service.name
              // )?.name!;
              navigation(`special`, {});
            }}
          />
        )}
      /> */}
    </Template>
  );
}

export default Accueil;

function CategorieCard({
  onClick,
  name,
  image,
  slogan,
}: {
  onClick: () => void;
  name: string;
  image: string;
  slogan?: "LS" | "LSP" | "LSR" | "R" | "S";
}) {
  return (
    <div
      className="border-solid border-2 rounded-xl border-gray overflow-hidden"
      onClick={onClick}
    >
      <div className="p-6 flex justify-center">
        <img
          src={`assets/demandes/${image}`}
          alt="test"
          className="h-[100px] object-contain"
        />
      </div>
      <div className="h-[1px] bg-[#c3c3c3] w-full"></div>
      <div className={`p-2 py-4 ${slogan === "S" ? "bg-green-200" : null}`}>
        <span className="block text-[.8rem] leading-tight font-bold">
          {name}
        </span>
      </div>
    </div>
  );
}
