import "./App.css";
import { Route, Routes } from "react-router-dom";
import { RouteInterfate } from "./setup/declarations";
import ROUTES from "./routes/indes.routes";
import RequireAuth from "./setup/auth/RequireAuth";
import WithoutAuth from "./setup/auth/WithoutAuth";

const recursiveRoutes = (routes: RouteInterfate[]) => {
  return (
    <>
      {routes.map((route: RouteInterfate, index: number) => {
        if (route.subPath) {
          return (
            <Route path={route.path} element={route.element} key={index}>
              {recursiveRoutes(route.subPath)}
            </Route>
          );
        }
        return <Route path={route.path} element={route.element} key={index} />;
      })}
    </>
  );
};

function Rooter() {
  return (
    <Routes>
      <Route path={"/"} element={<RequireAuth />}>
        {recursiveRoutes(ROUTES.APP.privateRoutes)}
      </Route>
      {recursiveRoutes(ROUTES.APP.freeRoutes)}
      <Route element={<WithoutAuth />}>
        {recursiveRoutes(ROUTES.APP.publicRoutes)}
      </Route>
    </Routes>
  );
}

export default Rooter;
