import { useSelector } from "react-redux";
import { getSkeep } from "../store/features/userSlice";

function useGetSkeep() {
  // const isAuthenticate = true;
  const isSkeep = useSelector(getSkeep);

  return isSkeep;
}

export default useGetSkeep;
