import React from "react";
import { Template } from "../../../../layouts/Template";
import TabLink from "../../../../components/tabLink";
import { Outlet, useLocation } from "react-router-dom";
import TopBar from "../../../../components/topBar";

function Lavage() {
  const location = useLocation();
  return (
    <Template
      topBar={<TopBar title={location?.state?.name} />}
      topBarStyle={{ height: 60 }}
      mainStyle={{ padding: "0px" }}
    >
      <div className="sticky top-[0px] z-50 bg-[#fff]">
        <TabLink
          data={[
            { href: "", label: "poids" },
            { href: "articles", label: "articles" },
          ]}
        />
      </div>

      <div className="py-6 px-2 z-10 h-full">
        <Outlet />
      </div>
    </Template>
  );
}

export default Lavage;
