import React, { useEffect, useState } from "react";
import { Template } from "../../../../../../layouts/Template";
import TopBar from "../../../../../../components/topBar";
import Button from "../../../../../../components/button";
import Divider from "../../../../../../components/divider";
import InputDate from "../../../../../../components/inputDate";
import toast from "react-hot-toast";
import {
  getDate,
  getOrderPrice,
  setCollectDate,
  setCollectHour,
  setExpressPrice,
} from "../../../../../../store/features/orderSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateTimeSlots } from "../../../../../../utils/getHours";
import { addOneDayOnDate } from "../../../../../../utils/addOnedayOnDate";

function Ramassage() {
  const navigation = useNavigate();

  const dispatch = useDispatch();

  const dateStored = useSelector(getDate);

  // const hourStored = useSelector(getHour);
  const OderPrice = useSelector(getOrderPrice);
  // console.log(
  //   `${dateStored?.getFullYear()}-${dateStored?.getMonth()}-${dateStored?.getDate()}`
  // );

  const [selectedDay, setSelectedDay] = useState("");
  const [selectedHour, setSelectedHour] = useState<string | null>(null);

  const [hours] = useState(generateTimeSlots());

  useEffect(() => {
    if (dateStored) {
      const date = new Date(dateStored);
      setSelectedDay(
        `${date?.getFullYear()}-${date?.getMonth()! > 10 ? "" : "0"}${
          date?.getMonth()! + 1
        }-${date?.getDate() > 10 ? "" : "0"}${date?.getDate()}`
      );
    }
  }, [dateStored]);

  return (
    <Template
      topBar={<TopBar title="Créneaux de ramassage" />}
      topBarStyle={{ height: 60 }}
      mainStyle={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      bottomBar={
        <div className="h-full flex items-center px-4 pb-2 gap-4">
          <Button
            title={`Express 24h`}
            // title={`Express 24h - ${OderPrice?.toFixed()} FCFA`}
            onClick={(e) => {
              e.preventDefault();
              if (selectedHour && selectedDay) {
                dispatch(
                  setCollectDate(new Date(`${selectedDay} ${selectedHour}`))
                );

                dispatch(
                  setCollectHour(
                    addOneDayOnDate(
                      new Date(`${selectedDay} ${selectedHour}`),
                      1
                    )
                  )
                );
                dispatch(setExpressPrice({ type: "Express24" }));

                navigation("/accueil/commande");
              } else {
                return toast.error("Selectionner un creneau");
              }
            }}
            style={{
              border: "1px solid gray",
            }}
          />
          <Button
            title={`Express 48h`}
            onClick={(e) => {
              e.preventDefault();

              if (selectedDay && selectedHour) {
                dispatch(
                  setCollectDate(new Date(`${selectedDay} ${selectedHour}`))
                );
                dispatch(
                  setCollectHour(
                    addOneDayOnDate(
                      new Date(`${selectedDay} ${selectedHour}`),
                      2
                    )
                  )
                );

                dispatch(setExpressPrice({ type: "Express48" }));
                navigation("repassage");
              } else {
                return toast.error("Selectionner un creneau");
              }
            }}
            style={{
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid gray",
            }}
          />
        </div>
      }
    >
      <div className="w-full">
        <p className="text-[.9rem] mb-2">Choisir la date de ramassage</p>
        <InputDate
          value={selectedDay.toString()}
          onChange={setSelectedDay}
          placeholder="Select a date"
        />
      </div>

      <Divider my={6} />
      <div className=" w-full">
        <p className="text-[.9rem]">Choisir l’heure de ramassage</p>
        <div className="grid gap-4 grid-cols-3 mt-4">
          {hours.map((hour) => (
            <div
              className={`${
                selectedHour === hour
                  ? "bg-green-500"
                  : "bg-gray hover:bg-green-100"
              } py-2 px-4 rounded-full text-center cursor-pointer `}
              key={hour}
              onClick={() => setSelectedHour(hour)}
            >
              <span className="text-[.8rem]">{hour}</span>
            </div>
          ))}
        </div>
      </div>
    </Template>
  );
}

export default Ramassage;
