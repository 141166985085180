import React from "react";
// import Input from "../../../../../../components/input";
import Button from "../../../../../../components/button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import Input from "../../../../../../components/input";
import {
  getOrderInfo,
  setPrice,
  setWeight,
} from "../../../../../../store/features/orderSlice";
import useGetCurrentService from "../../../../../../Hooks/useGetCurrentService";
// import useGetOders from "../../../../../../Hooks/useGetOders";

interface From {
  weight: number;
  number: number;
}

function Poids() {
  const dispatch = useDispatch();

  const order = useSelector(getOrderInfo);

  // const { price } = useGetOders();

  const serviceSlogan = useGetCurrentService();

  const navigation = useNavigate();

  const form = useForm<From>({
    initialValues: {
      weight: parseFloat(parseInt(order?.weigth.toString()).toFixed(1)) ?? 0,
      number: order?.number ?? "",
    },

    validate: {
      weight: (value) =>
        serviceSlogan === "R"
          ? null
          : value > 0
          ? null
          : "Veuillez saisir un poids valide",
      number: (value) =>
        value > 0 ? null : "Veuillez saisir une quantité valide",
    },
  });

  const handleContinue = (values: From) => {
    dispatch(setWeight(values));
    dispatch(setPrice());
    navigation("/accueil/creneau");
  };

  return (
    <div className="relative h-full ">
      <form onSubmit={form.onSubmit(handleContinue)}>
        <div className="flex flex-col gap-2">
          <span className="ml-2">Mettre le nombre d'articles</span>
          <Input
            placeholder="nombre d'articles"
            type="number"
            {...form.getInputProps("number")}
            error={form.errors?.number}
          />
        </div>

        {serviceSlogan === "R" ? null : (
          <div className=" h-40 flex items-center justify-center flex-col mt-10">
            <input
              className="bg-transparent text-[3rem] h-full w-full text-center outline-none border-none"
              placeholder="poids"
              type="number"
              {...form.getInputProps("weight")}
            />
            {form.errors?.weight ? (
              <span className="text-[red]">{form.errors?.weight}</span>
            ) : null}
          </div>
        )}

        <div className="absolute bottom-0 left-0 w-full p-4 flex flex-col gap-3 ">
          <Button
            title="faire une simulation"
            style={{
              backgroundColor: "transparent",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => navigation("articles")}
          />
          <Button type="submit" title={"Continuer"} />
        </div>
      </form>
    </div>
  );
}

export default Poids;
