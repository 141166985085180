import React from "react";
import BottomTab from "../../components/bottomTab";
import { Template } from "../../layouts/Template";
import Logo from "../../components/logo";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";

function Abonnement() {
  const navigation = useNavigate();

  return (
    <Template
      topBar={
        <div className="flex gap-4 h-24 items-center px-5 ">
          <Logo />
          <p className="text-[1rem] font-bold">Abonnement</p>
        </div>
      }
      topBarStyle={{ height: 80 }}
      bottomBar={<BottomTab />}
      mainStyle={{ padding: 10 }}
    >
      <h2 className="text-center font-bold text-[1rem]">
        Obtenez un accès illimité <br /> à nos programmes.
      </h2>

      <p className="text-center text-[.8rem] mt-4">
        Faites le premier pas vers une vie plus saine et plus heureuse.l
      </p>

      <div className="p-4 my-4 flex flex-nowrap overflow-x-scroll gap-2">
        <PricingCard onClick={() => navigation("payement")} />
        <PricingCard />
        <PricingCard />
      </div>

      <p className="text-[.8rem] text-center px-4">
        Vous serez facturé 25.000 FCFA (forfait mensuel) ou 70.000 FCFA (forfait
        annuel) via votre compte Fot Fat Fat. Vous pouvez suspendre
        temporairement ou annuler à tout moment si vous n'êtes pas satisfait.
      </p>
    </Template>
  );
}

export default Abonnement;

function PricingCard({ onClick }: { onClick?: () => void }) {
  return (
    <div className="border-2 border-gray rounded-xl flex flex-col p-6 items-center min-w-full">
      <p className="text-[.8rem]">POPULAIRE</p>
      <h3 className="font-bold">Forfait Mensuel</h3>
      <h1 className="font-[900] text-[2.1rem] my-1">25.000 F</h1>

      <p className="text-[.8rem] ">Pour un mois</p>

      <p className="font-[500] text-[.8rem] text-center mt-10 mb-4">
        Lorem ipsum dolor sit amet consectetur. Suspendisse erat.
      </p>

      <Button title="S'abonner" onClick={onClick} />
    </div>
  );
}
