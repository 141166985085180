import { useSelector } from "react-redux";
import { getUserStatus } from "../store/features/userSlice";

function useGetAuthenticate() {
  // const isAuthenticate = true;
  const isAuthenticate = useSelector(getUserStatus);

  return isAuthenticate;
}

export default useGetAuthenticate;
