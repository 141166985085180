import { useSelector } from "react-redux";
import { OderItem } from "../../setup/declarations";
import {
  OrderSlice,
  getDate,
  getHour,
  getOrderPrice,
  getOrderPriceExpress,
  getServiceId,
} from "../../store/features/orderSlice";

function useGetOders() {
  const orders: OderItem[] = useSelector(
    (state: { dataPersisted: { oders: OrderSlice } }) =>
      state.dataPersisted?.oders?.orders
  );
  const weight = useSelector(
    (state: { dataPersisted: { oders: OrderSlice } }) =>
      state.dataPersisted?.oders?.weigth
  );
  const amountArticle = useSelector(
    (state: { dataPersisted: { oders: OrderSlice } }) =>
      state.dataPersisted?.oders.number
  ).toString();
  const deliveryTime = useSelector(getHour);
  const collectionTime = useSelector(getDate);
  const serviceId = useSelector(getServiceId);
  const price = useSelector(getOrderPrice);
  const priceExpress = useSelector(getOrderPriceExpress);

  return {
    orders,
    collectionTime: collectionTime as unknown as Date,
    deliveryTime: deliveryTime as unknown as Date,
    weight: weight,
    amountArticle: parseInt(amountArticle),
    serviceId,
    price,
    priceExpress: priceExpress || price,
  };
}

export default useGetOders;
