// import API from "../../routes/api.routes";
import API from "../../routes/api.routes";
import { ApiMananger } from "../app/apiMananger";

export const orderApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getOrder: build.query({
      query: (id: number) => API.ORDER.GET(id),
      providesTags: ["Orders"],
    }),
    getAllOrders: build.query({
      query: () => API.ORDER.GETALL(),
      providesTags: ["Orders"],
    }),
    getAllUserOrders: build.query({
      query: () => API.ORDER.USER(),
      providesTags: ["Orders"],
    }),
    placeOrder: build.mutation({
      query(body: any) {
        return {
          url: API.ORDER.ADD(),
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const {
  useGetOrderQuery,
  useGetAllOrdersQuery,
  useGetAllUserOrdersQuery,
  usePlaceOrderMutation,
} = orderApi;
